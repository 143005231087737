import React from 'react'
import AdminSignIn from '../components/adminSignIn';
import Header from '../components/header';

const Admin = () => {
  return <>
    <Header />
    <AdminSignIn />
  </>
}

export default  Admin
