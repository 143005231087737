import { Project } from '../lib/model';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useProject } from '../hooks/useProject';

const QuestionList = ({ project }: { project: Project }) => {
  const { updateProject } = useProject()

  if (!project.questions) {
    return <Box>
      Keine Fragen gefunden
    </Box>
  }

  const questions = Object.values(project.questions)

  const questionColumns: GridColDef[] = [
    {
      field: 'id', headerName: 'Id', width:5, renderCell: (params) => {
        return (<>{parseInt(params.row.id.substr(3))}</>)
      },
      valueGetter: params => parseInt(params.row.id.substr(3))
    },
    {
      field: 'context', headerName: 'Context',flex:0.2, renderCell: (params) => {
        return (<>{params.row.context}</>)
      }
    },
    {
      field: 'deutsch', headerName: 'Deutsch', flex:0.4,  renderCell: (params) => {
        return (
          <Box sx={{p:1}}>
            <Box>he: {params.row.de.he}</Box>
            <Box>she: {params.row.de.she}</Box>
            <Box>me: {params.row.de.me}</Box>
          </Box>
        )
      }
    },
    {
      field: 'englisch', headerName: 'Englisch', flex:0.4, renderCell: (params) => {
        return (
          <Box sx={{p:1}}>
            <Box>he: {params.row.en.he}</Box>
            <Box>she: {params.row.en.she}</Box>
            <Box>me: {params.row.en.me}</Box>
          </Box>
        )
      }
    },
  ]


  const deleteQuestions = () => {
    updateProject({ ...project, questions: null})
  }

  return <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>

      </Typography>
      <Button variant="contained" color="error" onClick={deleteQuestions}>
        Alles Löschen
      </Button>
    </Box>
    <DataGrid
      columns={questionColumns}
      rows={questions}
      autoHeight
      disableSelectionOnClick
      getRowHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }]
        }
      }}
    />
    </>
}

export default QuestionList
