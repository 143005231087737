import SpiderChart, { SpiderData } from './spiderChart';
import { AnalysisBasicData, AnalysisBasicDataElement, Context, Gender, Language, Project } from '../lib/model';
import React, { useContext, useEffect, useState } from 'react';
import { getMean, getSD } from '../lib/analysis/analysisUtils';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import BarChart, { BarChartData } from './barChart';
import HorizontalBarChart,{ HorizontalBarChartData } from './horizontalBarChart';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { usePDF } from '../hooks/usePDF';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import LanguageSelector from './languageSelector';
import { LanguageContext } from '../lib/context';
import { useContexts } from '../hooks/useContexts';

type Props = { analyticsBasic: AnalysisBasicData, project: Project, clientGender: Gender, clientId: string }

const Charts = ({ analyticsBasic, project, clientGender, clientId }: Props) => {

  const [showSpider, setShowSpider] = useState(true)
  const [showContext, setShowContext] = useState(true)
  const [showQuestion, setShowQuestion] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showFrontpage, setShowFrontpage] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [description, setDescription] = useState('')

  const [dataByContextAndType, setDataByContextAndType] = useState<SpiderData>([])
  const [dataByContexAndRole, setDataByContextAndRole] = useState<{ [key: string]: BarChartData }>({})
  const [dataByQuestion, setDataByQuestion] = useState<{ roles: HorizontalBarChartData, context: string, question: string }[]>([])
  const titles: string[] = []

  useEffect(() => {
    if (analyticsBasic) {
      setDataByContextAndType(generateDataByContextAndTyp(analyticsBasic))
      setDataByContextAndRole(generateDataByContextAndRole(analyticsBasic))
      setDataByQuestion(generateDataByQuestion(analyticsBasic))
    }
  }, [analyticsBasic])

  const { generatePdf, addCanva } = usePDF()
  const { translateText, language } = useContext(LanguageContext)
  const { contexts } = useContexts()

  const onButtonClick = () => {
    setIsLoading(true)
  }

  const toggleModal = () => {
    setShowModal(current => !current)
  }

  useEffect(() => {
    if (project.clients && isLoading) {
      generatePdf(showFrontpage, project.clients[clientId], description).then(() => {
        setIsLoading(false)
        toggleModal()
      })
    }
  }, [isLoading])

  const getQuestionById = (id: string): string => {
    // @ts-ignore
    const lang: Language = language ?? "de"
    const question = project.questions?.[id]
    if(question){
      if(lang === 'en' && question.en?.she && question.en?.he) {
        return clientGender === 'w' ? question.en.she : question.en.he;
      }
      if(question.de && question.de?.she && question.de?.he) {
        return clientGender === 'w' ? question.de.she : question.de.he;
      }
    }
    return '-'
  }
  const getCommentsById = (id: string): string[] => {
    const comments: string[] = []
    if (project.feedbackers) {
      Object.keys(project.feedbackers).forEach((feedbackerId) => {
        if (project.feedbackers?.[feedbackerId]?.clients?.[clientId]?.answers?.[id]?.remark) {
          comments.push(project.feedbackers?.[feedbackerId]?.clients?.[clientId]?.answers?.[id]?.remark!)
        }
      })
    }
    return comments
  }

  const getContext = (contextId: string) => {
    const usedContext = contexts.find((currentContext: Context) => currentContext.id === contextId)
    return translateText && usedContext && translateText({ de: usedContext.de, en: usedContext.en })
  }

  return <>
    <Dialog open={showModal}>
      <DialogTitle>
        Exportoptionen
      </DialogTitle>
      <DialogContent>
        <FormGroup sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '800px' }}>
          <FormControlLabel
            control={<Checkbox checked={showFrontpage} onChange={event => setShowFrontpage(event.target.checked)}/>}
            label="Deckblatt"/>
          <FormControlLabel
            control={<Checkbox checked={showDescription} onChange={event => setShowDescription(event.target.checked)}/>}
            label="Beschreibung"/>
        </FormGroup>
        {showDescription && <ReactQuill value={description} onChange={value => setDescription(value)}/>}

      </DialogContent>
      <DialogActions>
        Das Generieren kann einige Sekunden dauern.
        <Button variant="outlined" color="primary" sx={{ ml: 3 }} onClick={toggleModal}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={onButtonClick}>Generieren</Button>
      </DialogActions>
    </Dialog>
    <Box>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '800px' }}>
        <FormControlLabel
          control={<Checkbox checked={showSpider} onChange={event => setShowSpider(event.target.checked)}/>}
          label="Radar"/>
        <FormControlLabel
          control={<Checkbox checked={showContext} onChange={event => setShowContext(event.target.checked)}/>}
          label="Bar pro Kontext"/>
        <FormControlLabel
          control={<Checkbox checked={showQuestion} onChange={event => setShowQuestion(event.target.checked)}/>}
          label="Bar pro Frage"/>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'row' }}>
          <LanguageSelector style={{ marginRight: '20px', marginTop: '10px' }}/>
          <Button onClick={toggleModal} variant="contained">PDF {isLoading && 'wird generiert...'}</Button>
        </Box>
      </FormGroup>
    </Box>
    {translateText && <Box sx={{ p: 2 }}>
      {showSpider &&
        <Box sx={{ pb: 4 }}>
          <Typography variant="h4" sx={{ pb: 2 }}>{translateText({
            de: 'Gesamtübersicht',
            en: 'General overview'
          })}</Typography>
          <SpiderChart data={dataByContextAndType} addCanva={addCanva}/>
        </Box>
      }
      {showContext &&
        <Box>
          <Typography variant="h4">{translateText({
            de: 'Übersicht pro Kompetenzbereich',
            en: 'Overview the areas of competence'
          })}</Typography>
          {Object.entries(dataByContexAndRole).map(([context, data]) =>
            <>
              {data.length > 0 &&
                <Box sx={{ mt: 3, mb: 3, display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', width: '800px' }}
                     key={'context-' + context}>
                  <Box sx={{ pt: 2 }}>{getContext(context)}</Box>
                  <BarChart data={data} contextId={context} addCanva={addCanva}/>
                </Box>}
            </>
          )}
        </Box>
      }
      {showQuestion &&
        <Box>
          {dataByQuestion.map((data) =>
            <>{data.roles.length > 0 &&
              <Box key={data.question}>
                {data.context && !titles.includes(data.context) && titles.push(data.context) > 0 &&
                  <Typography sx={{ mt: 3 }} variant="h4">{getContext(data.context)}</Typography>}

                <>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', mt: 3, mb: 3, width: '800px' }}>
                    <Box sx={{ pt: 2 }}>{data.question.replace('id-', '')}. {project.clients?.[clientId].firstname} {project.clients?.[clientId].lastname} {getQuestionById(data.question)}</Box>
                    <HorizontalBarChart data={data.roles} addCanva={addCanva} id={data.question}
                                        question={data.question.replace('id-', '') +". " + project.clients?.[clientId].firstname + ' ' +project.clients?.[clientId].lastname +' '+getQuestionById(data.question)}
                                        remarks={getCommentsById(data.question)}
                                        contextId={data.context}
                    />
                  </Box>
                  {getCommentsById(data.question).length > 0 && <Box sx={{ width: '800px' }}>
                    <b>{translateText({de: 'Kommentare', en: 'comments'})}</b>
                    {getCommentsById(data.question).map((comment: string, index: number) => <div
                      key={`comment-${data.question}-${index}`}>{comment}</div>)}
                  </Box>}
                </>
              </Box>
            }</>
          )}
        </Box>
      }
    </Box>}
  </>
}

const generateDataByQuestion = (analyticsBasic: AnalysisBasicData): { roles: HorizontalBarChartData, context: string, question: string }[] => {

  return Object.entries(analyticsBasic).reduce(
    (all: { roles: HorizontalBarChartData, context: string, question: string }[], [question, questionElement]) => {
      all.push({
        question,
        context: questionElement.context,
        roles: calcDataForRoles(questionElement.roles)
      })
      return all
    }, []).sort((a, b) => {
    const aNumber = parseInt(a.question.substr(3))
    const bNumber = parseInt(b.question.substr(3))
    return aNumber - bNumber
  })
}

const calcDataForRoles = (roles: { [key: string]: number[] }): HorizontalBarChartData => {
  return Object.entries(roles).map(([role, values]) => {
    return { role, mean: getMean(values), sd: getSD(values) }
  }).sort((a, b) => a.role.localeCompare(b.role))
}

const generateDataByContextAndRole = (analyticsBasic: AnalysisBasicData): { [key: string]: BarChartData } => {

  const contexts = getAllContexts(analyticsBasic);
  const roles = getAllRoles(analyticsBasic)

  return contexts.reduce((dataByContextAndRole: { [key: string]: { role: string, mean: number, sd: number }[] }, context) => {
    dataByContextAndRole[context] = roles.reduce(
      (allRolesData: { role: string, mean: number, sd: number }[], role) => {
        const allValuesByRole = getAllValuesByRoleAndContext(analyticsBasic, role, context);
        if (allValuesByRole.length > 0) {
          allRolesData.push({ role, mean: getMean(allValuesByRole), sd: getSD(allValuesByRole) })
          allRolesData.sort((a, b) => a.role.localeCompare(b.role))
        }
        return allRolesData;
      }, [])
    return dataByContextAndRole
  }, {})
}

const getAllValuesByRoleAndContext = (analyticsBasic: AnalysisBasicData, role: string, context: string): number[] => {
  return Object.values(analyticsBasic).filter(questionElement => questionElement.context === context)
    .reduce((allValues: number[], questionElement) => {
      return allValues.concat(questionElement.roles[role] ?? [])
    }, [])
}

const getAllContexts = (analyticsBasic: AnalysisBasicData): string[] => {
  return Object.values(analyticsBasic).reduce((contexts: string[], questionElement: AnalysisBasicDataElement) => {
    if (!contexts.includes(questionElement.context)) {
      contexts.push(questionElement.context)
    }
    return contexts
  }, [])
}

const getAllRoles = (analyticsBasic: AnalysisBasicData): string[] => {
  return Object.values(analyticsBasic).reduce((roles: string[], questionElement: AnalysisBasicDataElement) => {
    Object.keys(questionElement.roles).forEach(role => {
      if (!roles.includes(role)) {
        roles.push(role)
      }
    })
    return roles
  }, [])
}

const generateDataByContextAndTyp = (analyticsBasic: AnalysisBasicData): SpiderData => {
  const tempWithAllValues = Object.values(analyticsBasic).reduce<{ [key: string]: { self: number[], others: number[] } }>
  ((allByContext, questionElement) => {
    if (!allByContext[questionElement.context]) {
      allByContext[questionElement.context] = {
        self: getAllSelfValues(questionElement),
        others: getAllOtherValues(questionElement)
      }
    } else {
      allByContext[questionElement.context] = {
        self: allByContext[questionElement.context].self.concat(getAllSelfValues(questionElement)),
        others: allByContext[questionElement.context].others.concat(getAllOtherValues(questionElement))
      }
    }
    return allByContext
  }, {})

  const tempAvgDataByContextAndTyp = Object.entries(tempWithAllValues).reduce(
    (all: SpiderData, [context, content]) => {
      all.push({ context, self: getMean(content.self), others: getMean(content.others) })
      return all
    }, [])

  return tempAvgDataByContextAndTyp.sort((a, b) => a.context.localeCompare(b.context))
}

const getAllSelfValues = (questionElement: AnalysisBasicDataElement) => {
  if (questionElement.roles['self']) {
    return [...questionElement.roles['self']]
  }
  return []
}

const getAllOtherValues = (questionElement: AnalysisBasicDataElement) => {
  let toReturn: number[] = []
  Object.entries(questionElement.roles).forEach(([key, element]) => {
    if (key !== 'self') {
      toReturn = toReturn.concat(element)
    }
  })
  return toReturn
}

export default Charts
