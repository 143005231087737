import { useRoles } from '../hooks/useRoles';
import React from 'react'
import { MenuItem, Select } from '@mui/material';
import { Role } from '../lib/model';

const RoleSelector = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
  const { roles } = useRoles()
  roles.sort((roleA: Role, roleB: Role) => roleA.id.localeCompare(roleB.id))

  return <>{ roles && roles.find((role: Role) => role.id === value) &&
    <Select id="roleSelector" value={value} onChange={event => onChange(event.target.value)}>
      {roles.sort((roleA: Role, roleB: Role) => roleA.de.name.she.localeCompare(roleB.de.name.she)).map((role: Role) => <MenuItem key={'roleSelection' + role.id} value={role.id}>{role.de.name.she}</MenuItem>)}
    </Select>}
  </>
}


export default RoleSelector
