import { Project } from '../lib/model';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { firebaseDb } from '../lib/firebase';
import { useEffect, useState } from 'react';


export const useProjects = (): any => {

  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
      onSnapshot(query(collection(firebaseDb, 'projects')), (snapshot => {
        const temp: Project[] = []
        snapshot.forEach(doc => {
          const data = doc.data() as Project
          temp.push({ ...data, id: doc.id })
        })
        setProjects(temp)
      }));
    }
    , [])

  return { projects }
}
