import { parse } from 'csv-parse/browser/esm/sync';
import { Client, Context, Feedbacker, Gender, Project, Question, Role, RolePerLang } from './model';
import { v4 as uuidv4 } from 'uuid';
import { collection, getDocs, query } from 'firebase/firestore';
import { createUserCall, firebaseDb } from './firebase';
import React from 'react';

const SELFTEXT = 'Selbsteinschätzung'
const UNKOWN = 'unbekannt'

export const parseQuestions = async (file: File): Promise<Pick<Project, 'questions'>> => {
  const objectToReturn: Pick<Project, 'questions'> = { questions: {} }

  const contexts = await getContexts()

  file && await file.text().then(async (content) => {
    try {
      const jsonInput = await parse(content, { delimiter: ';' })
      let counter = 0
      jsonInput.forEach((line: string[]) => {
        const context = getContextByLine(line, contexts)
        let question: Question;
        if (context !== UNKOWN) {
          counter = counter + 1
          question = {
            id: `id-${counter}`,
            questionNumber: `${counter}`,
            context,
            de: {
              he: line[questionCSVMap.he_de].length > 0 ? line[questionCSVMap.he_de] : line[questionCSVMap.she_de],
              she: line[questionCSVMap.she_de].length > 0 ? line[questionCSVMap.she_de] : line[questionCSVMap.he_de],
              me: line[questionCSVMap.me_de],
            },
            en: {
              he: line[questionCSVMap.he_en].length > 0 ? line[questionCSVMap.he_en] : line[questionCSVMap.she_en],
              she: line[questionCSVMap.she_en].length > 0 ? line[questionCSVMap.she_en] : line[questionCSVMap.he_en],
              me: line[questionCSVMap.me_en],
            },
          }
          objectToReturn.questions![question.id] = question
        }
      })

    } catch (e) {
      console.log(e)
    }
  })
  return objectToReturn
}

export const parsePersons = async (file: File): Promise<Pick<Project, 'clients' | 'feedbackers'>> => {

  const objectToReturn: Pick<Project, 'clients' | 'feedbackers'> = { clients: {}, feedbackers: {} }
  file && await file.text().then(async (content) => {
    try {
      const jsonInput = await parse(content, { delimiter: ';' })

      // TODO: Check ob das File korrekt ist

      const roles = await getRoles()
      let client: Client;

      jsonInput.forEach((line: string[]) => {
          if ('Status der Einschätzung' === line[clientCSVMap.role]
            || !line[clientCSVMap.name]
            || !line[clientCSVMap.firstname]
            || !line[clientCSVMap.mail]) {
            return
          }

          if (checkIfClient(line)) {

            client = {
              id: uuidv4(),
              firstname: line[clientCSVMap.firstname],
              lastname: line[clientCSVMap.name],
              gender: line[clientCSVMap.gender] as Gender,
              mail: line[clientCSVMap.mail]
            }

            if (Object.values(objectToReturn.clients!).filter(existingClient => isEntryEqual(existingClient, client, ['id'])).length < 1) {
              objectToReturn.clients![client.id] = client
            }
          }

          let feedbacker: Feedbacker = {
            id: uuidv4(),
            clients: {},
            firstname: line[clientCSVMap.firstname],
            lastname: line[clientCSVMap.name],
            gender: line[clientCSVMap.gender] as Gender,
            mail: line[clientCSVMap.mail]
          }

          let existingFeedbacker = Object.values(objectToReturn.feedbackers!)
            .find(innerFeedbacker => {
                return isEntryEqual(innerFeedbacker, feedbacker, ['id', 'clients'])
              }
            )

          if (existingFeedbacker) {
            feedbacker = existingFeedbacker
          } else {
            createUserCall({ mail: feedbacker.mail, pass: '123456', uid: feedbacker.id })
          }

          if (client) {
            feedbacker.clients[client.id] = { id: client.id, role: getRoleIdByDeName(line[clientCSVMap.role], roles) }
          }

          objectToReturn.feedbackers![feedbacker.id] = feedbacker
        }
      )
    } catch (e) {
      console.log(e)
    }
  })

  return objectToReturn
}

const getRoleIdByDeName = (roleDeName: string, roles: Role[]): string => {
  if (roleDeName === SELFTEXT || roleDeName === 'Ich') {
    roleDeName = 'self'
  }
  const roleId = roles.find(role => role.id === roleDeName.toLowerCase())?.id ?? UNKOWN
  if(roleId === UNKOWN){
    alert('Keine passende Rolle gefunden für '+ roleDeName+ ', bitte Referenz der Rolle verwenden.')
  }
  return roleId
}

const getContextByLine = (line: string[], contexts: Context[]): string => {
  let id = UNKOWN

  Object.entries(contexts).forEach(([key, value]) => {
    if (line[questionCSVMap.context_de] === key
      || line[questionCSVMap.context_en] === key
      || (line[questionCSVMap.contextDescription_de] === value.de && value.de.length > 0)
      || (line[questionCSVMap.contextDescription_en] === value.en && value.en.length > 0))
    {
      id = value.id
    }
  })

  return id;
}

const checkIfClient = (line: string[]) => line[clientCSVMap.role] === SELFTEXT

const isEntryEqual = (elementA: any, elementB: any, skipKeys: string[]) => {

  if (Object.keys(elementA).length !== Object.keys(elementB).length) {
    return false
  }

  let isEqual = true
  Object.entries(elementA).forEach(([key, value]) => {
    if (skipKeys.includes(key)) {
      isEqual = true
    } else {
      if ((value as string).toLowerCase() !== elementB[key].toLowerCase()) {
        isEqual = false
      }
    }
  })

  return isEqual
}

const getRoles = async (): Promise<Role[]> => {
  const roles: Role[] = []
  await getDocs(query(collection(firebaseDb, 'roles'))).then((snapshot => {
        snapshot.forEach(doc => {
          const data = doc.data() as { de: RolePerLang, en: RolePerLang, color: string }
          roles.push({ ...data, id: doc.id })
        })
      }
    )
  )
  return roles
}

const getContexts = async (): Promise<Context[]> => {
  const contexts: Context[] = []
  await getDocs(query(collection(firebaseDb, 'contexts'))).then((snapshot => {
        snapshot.forEach(doc => {
          const data = doc.data() as Pick<Context, 'de' | 'en'>
          contexts.push({ ...data, id: doc.id })
        })
      }
    )
  )
  return contexts
}

const clientCSVMap = {
  role: 0,
  fb_nehmer_firstname: 1,
  fb_nehmer_name: 2,
  firstname: 3,
  name: 4,
  gender: 5,
  mail: 6,
};

const questionCSVMap = {
  context_de: 0,
  contextDescription_de: 1,
  he_de: 2,
  heCount_de: 3,
  she_de: 4,
  sheCount_de: 5,
  me_de: 6,
  meCount_de: 7,
  context_en: 8,
  contextDescription_en: 9,
  he_en: 10,
  heCount_en: 11,
  she_en: 12,
  sheCount_en: 13,
  me_en: 14,
  meCount_en: 15,
};


