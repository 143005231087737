import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Client, Feedbacker, Project } from '../lib/model';
import RoleSelector from './roleSelector';
import { Add, Close } from '@mui/icons-material';
import * as _ from 'lodash';
import { useProject } from '../hooks/useProject';
import { updateMail } from '../lib/firebase';

type LinkedPerson = {
  id: string,
  firstname: string,
  lastname: string,
  role: string | null
}

const PersonDetails = ({
                         project,
                         inputPerson,
                         inputType,
                         showDialog = false,
                         setShowDialog
                       }: { project: Project, inputPerson: Feedbacker | Client, inputType: 'client' | 'feedbacker', showDialog: boolean, setShowDialog: (value: boolean) => void }) => {

  const [person, setPerson] = useState<Feedbacker | Client | null>(null)
  const [linkedPersons, setLinkedPersons] = useState<any[]>([])


  useEffect(() => {
    let tempLinkedPersons: LinkedPerson[] = []
    if (inputType === 'feedbacker' && project.clients && person && (person as Feedbacker).clients) {
      const feedbackerClients = (person as Feedbacker).clients
      const clients = Object.values(project.clients)
      tempLinkedPersons = clients.map(client => ({
        id: client.id,
        firstname: client.firstname,
        lastname: client.lastname,
        role: feedbackerClients[client.id] ? feedbackerClients[client.id].role : null
      }))
    }
    if (inputType === 'client' && project.feedbackers && person?.id) {
      const feedbackersForClient = Object.values(project.feedbackers)
      tempLinkedPersons = feedbackersForClient.map(feedbacker => ({
        id: feedbacker.id,
        firstname: feedbacker.firstname,
        lastname: feedbacker.lastname,
        role: feedbacker.clients[person.id] ? feedbacker.clients[person.id].role : null
      }))
    }

    setLinkedPersons(tempLinkedPersons)

  }, [person])

  const { updateProject } = useProject(project.id)

  const feedbackerClientsColumns: GridColDef[] = [
    {
      field: 'firstname', headerName: 'Vorname', flex: 0.3, renderCell: (params) => {
        return (<>{params.row.firstname}</>)
      }
    },
    {
      field: 'lastname', headerName: 'Nachname', flex: 0.3, renderCell: (params) => {
        return (<>{params.row.lastname}</>)
      }
    },
    {
      field: 'role', headerName: 'Als Role', flex: 0.4, renderCell: (params) => {
        return (<>
          {params.row.role ?
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}><RoleSelector
              value={params.row.role} onChange={selection => {
              setRoleForLinkedPerson(params.row, selection)
            }}/> <IconButton aria-label="remove" color="error"
                             onClick={() => removeLinkedPerson(params.row)}>
              <Close/>
            </IconButton></Box>
            : <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <IconButton aria-label="add" color="success"
                          onClick={() => {
                            addLinkedPerson(params.row)
                          }}>
                <Add/>
              </IconButton>
            </Box>
          }
        </>)
      }
    }
  ]


  useEffect(() => {
    setPerson(inputPerson)
  }, [inputPerson])


  if (!person) {
    return <></>
  }

  const toggleDialog = () => {
    setShowDialog(!showDialog)
  }

  const setData = (field: string, content: any) => {
    if (person) {
      const temp = _.cloneDeep(person)
      _.set(temp, field, content)
      setPerson(temp)
    }
  }

  const removeLinkedPerson = (linkedPerson: LinkedPerson): void => {
    if (window.confirm(`Wollen Sie ${linkedPerson.firstname} ${linkedPerson.lastname} wirklich entfernen ? Es werden alle bereits gemachten Antworten ebenfalls gelöscht.`)) {
      linkedPerson.role = null
      setLinkedPersons(current => current.map(currentLinkedPerson => currentLinkedPerson.id === linkedPerson.id ? linkedPerson : currentLinkedPerson))
    }
  }

  const addLinkedPerson = (linkedPerson: LinkedPerson) => {
    linkedPerson.role = 'mitarbeiter'
    setLinkedPersons(current => current.map(currentLinkedPerson => currentLinkedPerson.id === linkedPerson.id ? linkedPerson : currentLinkedPerson))
  }

  const setRoleForLinkedPerson = (linkedPerson: LinkedPerson, roleId: string) => {
    linkedPerson.role = roleId
    setLinkedPersons(current => current.map(currentLinkedPerson => currentLinkedPerson.id === linkedPerson.id ? linkedPerson : currentLinkedPerson))
  }

  const savePerson = () => {
    if (!person) {
      return
    }
    if (inputType === 'feedbacker') {

      const tempPerson = _.cloneDeep(person) as Feedbacker
      linkedPersons.forEach(linkedPerson => {
        if (linkedPerson.role) {
          if (!tempPerson.clients[linkedPerson.id]) {
            tempPerson.clients[linkedPerson.id] = { id: linkedPerson.id, role: linkedPerson.role }
          } else {
            tempPerson.clients[linkedPerson.id].role = linkedPerson.role
          }
        }
        if (!linkedPerson.role && tempPerson.clients[linkedPerson.id]) {
          delete tempPerson.clients[linkedPerson.id]
        }
      })

      updateProject({
        ...project,
        feedbackers: { ...project.feedbackers, [tempPerson.id]: tempPerson }
      })

      if (tempPerson.mail !== inputPerson.mail) {
        updateMail({ mail: inputPerson.mail, newMail: tempPerson.mail })
      }
      toggleDialog()
    }
    if (inputType === 'client') {

      const tempProject = _.cloneDeep(project)
      tempProject.clients = { ...project.clients, [person.id]: person }


      linkedPersons.map(linkedPerson => {
          if (tempProject.feedbackers?.[linkedPerson.id]) {
            if (linkedPerson.role) {
              if (!tempProject.feedbackers[linkedPerson.id].clients?.[person.id]) {
                tempProject.feedbackers[linkedPerson.id].clients[person.id] = {
                  id: person.id,
                  role: linkedPerson.role
                }
              } else {
                tempProject.feedbackers[linkedPerson.id].clients[person.id].role = linkedPerson.role
              }
            }
            if (!linkedPerson.role && tempProject.feedbackers[linkedPerson.id].clients[person.id]) {
              delete tempProject.feedbackers[linkedPerson.id].clients[person.id]
            }
          } else {
            console.log('Feedbacker not found for id', linkedPerson.id, 'in client save function')
          }
        }
      )

      updateProject(tempProject)
      if (person.mail !== inputPerson.mail) {
        updateMail({ mail: inputPerson.mail, newMail: person.mail })
      }
      toggleDialog()
    }
  }

  return <Dialog open={showDialog} onClose={toggleDialog}>
    <DialogTitle>
      {person.firstname} {person.lastname} bearbeiten
    </DialogTitle>
    <DialogContent>
      <Box sx={{ minWidth: '500px', mt: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <TextField
            sx={{ width: '100%', mb: 2, mr: 2 }}
            id="id"
            label="Vorname"
            value={person.firstname}
            onChange={(event) => setData('firstname', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Nachname"
            value={person.lastname}
            onChange={(event) => setData('lastname', event.target.value)}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TextField
            sx={{ width: '80%', mb: 2, mr: 2 }}
            id="id"
            label="Mail"
            value={person.mail}
            onChange={(event) => setData('mail', event.target.value)}
          />
          <TextField
            sx={{ width: '20%', mb: 2 }}
            id="id"
            label="Geschlecht"
            value={person.gender}
            onChange={(event) => setData('gender', event.target.value)}
          />
        </Box>
      </Box>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Kunden
      </Typography>
      <Box>
        <DataGrid
          columns={feedbackerClientsColumns}
          autoHeight
          rows={linkedPersons}
          disableSelectionOnClick
          initialState={{
            pagination: {
              pageSize:5
            },
            sorting: {
              sortModel: [{ field: 'firstname', sort: 'asc' }]
            }
          }}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={toggleDialog}>Abbrechen</Button>
      <Button variant="contained" color="primary" onClick={savePerson}>Speichern</Button>
    </DialogActions>
  </Dialog>
}

export default PersonDetails
