import { Role, RolePerLang } from '../lib/model';
import { collection, query, onSnapshot, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { firebaseDb } from '../lib/firebase';
import { useEffect, useState } from 'react';
import _ from 'lodash';


export const useRoles = (): any => {

  const [roles, setRoles] = useState<Role[]>([])

  const updateRole = (newRole: Partial<Role>) => {
    const data: Partial<Role> = _.cloneDeep(newRole);
    delete data.id;
    setDoc(doc(firebaseDb, 'roles', newRole.id!), data)
  }

  const deleteRole = (roleToDelete: Role) => {
   deleteDoc(doc(firebaseDb,'roles', roleToDelete.id))
  }

  useEffect(() => {
      onSnapshot(query(collection(firebaseDb, 'roles')), (snapshot => {
        const temp: Role[] = []
        snapshot.forEach(doc => {
          const data = doc.data() as { de: RolePerLang, en: RolePerLang, color: string }
          temp.push({ ...data, id: doc.id })
        })
        setRoles(temp)
      }));
    }
    , [])


  const getRoleById = (roleId: string) => {
    if(roles){
      return roles.find((role) => role.id === roleId)
    }
  }

  return { roles , updateRole, deleteRole, getRoleById}
}
