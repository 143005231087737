import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/header';
import { useParams } from 'react-router-dom';
import { useProject } from '../hooks/useProject';
import { Client, ClientRef, Feedbacker, Project, Question } from '../lib/model';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import * as _ from 'lodash';
import { parseInt } from 'lodash';
import { green, grey } from '../lib/analysis/analysisUtils';
import { useRoles } from '../hooks/useRoles';
import { AuthContext, LanguageContext } from '../lib/context';
import FeedbackerSignIn from '../components/feedbackerSignIn';
import { Close } from '@mui/icons-material';
import LanguageSelector from '../components/languageSelector';
import Questionnarie from '../components/questionnarie';


const Feedback = () => {
  const { projectId, feedbackerId } = useParams()
  const { user, isAdmin } = useContext(AuthContext)
  const { singleProject, updateProject } = useProject(projectId)
  const { getRoleById } = useRoles()
  const { language, translateText, setLanguage } = useContext(LanguageContext)

  const [questions, setQuestions] = useState<Question[] | null>(null)
  const [feedbacker, setFeedbacker] = useState<Feedbacker | null>(null)
  const [localProject, setLocalProject] = useState<Project>()
  const [isOpen, setIsOpen] = useState(true)
  const [singleLanguage, setSingleLanguage] = useState<boolean>(false)
  const [currentClientNumber, setCurrentClientNumber] = useState(0)
  const [currentClient, setCurrentClient] = useState<ClientRef | null>()

  const userToken = sessionStorage.getItem('Auth Token')

  useEffect(() => {
    if (singleProject && !localProject) {
      setLocalProject(singleProject)
    }
  }, [singleProject])

  useEffect(() => {
    if (feedbackerId && localProject) {
      setFeedbacker(localProject.feedbackers![feedbackerId])
      setQuestions((Object.values(localProject.questions!) as Question[]).sort((a, b) => parseInt(a.questionNumber) - parseInt(b.questionNumber)))
      setCurrentClientNumber(currentClientNumber ?? 0)
      if (localProject.clientBanner.en && !localProject.clientBanner.de) {
        setSingleLanguage(true)
        setLanguage!('en')
      } else if (localProject.clientBanner.de && !localProject.clientBanner.en) {
        setSingleLanguage(true)
        setLanguage!('de')
      }
    }
  }, [localProject])

  useEffect(() => {
    if (feedbacker) {
      setCurrentClient(Object.values(feedbacker.clients).sort((a, b) => a.id.localeCompare(b.id))[currentClientNumber])
    }
  }, [currentClientNumber, feedbacker])

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  const setData = (field: string, content: any) => {
    if (localProject) {
      const temp = _.cloneDeep(localProject)
      _.set(temp, field, content)
      updateProject(temp)
      setLocalProject(temp)
    }
  }

  const getRoleWithLang = (client: ClientRef) => {
    const role = getRoleById(client.role)

    if (language === 'en' && role.en) {
      return role.en
    }
    if (role.de) {
      return role.de
    }
  }
  const getClientById = (clientId: string, project: Project): Client => {
    return project.clients![clientId];
  }
  const getTotalQuestionCount = () => localProject!.questions && feedbacker?.clients ? (Object.keys(localProject!.questions).length * Object.keys(feedbacker.clients).length) : 0
  const getQuestionCount = (): number => {

    if (!feedbacker) {
      return 0
    }
    return Object.values(feedbacker.clients).reduce((totalCount, client) => {
      const toAdd = client.answers ? Object.keys(client.answers).length : 0;
      return totalCount + toAdd;
    }, 0)
  }

  const finish = () => {
    setData(`feedbackers.${feedbackerId}.finished`, true)
  }


  return <>
    <Header/>
    <Box sx={{
      backgroundColor: grey + '44',
      width: '100vw',
      pt: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: 'calc(100vh - 96px)'
    }}>
      {!user && !userToken && <FeedbackerSignIn/>}
      {user && <>
        {feedbackerId !== user['uid'] && !isAdmin &&
          <Paper
            sx={{ pt: 3, pb: 3, width: { xs: '100vw', sm: '100vw', md: '70vw' }, textAlign: 'center' }}>
            <Typography variant="h5" sx={{ color: green }}>
              Sie sind nicht berechtigt diesen Fragebogen zu bearbeiten.<br/>
              Prüfen Sie noch mal Ihre Einladung, Danke.
            </Typography>
          </Paper>}

        {localProject && <>
          {!localProject.active &&
            <Paper sx={{ pt: 3, pb: 3, width: { xs: '100vw', sm: '100vw', md: '70vw' }, textAlign: 'center' }}>
              <Typography variant="h5" sx={{ color: green }}>
                Diese Umfrage wurde deaktiviert.
              </Typography>
            </Paper>
          }
          {localProject && localProject.active && feedbackerId && feedbacker && (feedbackerId === user['uid'] || isAdmin) && currentClient &&
            <>
              <Dialog
                open={isOpen && (isAdmin || !((feedbacker.finished ?? false) || (user && user['uid'] !== feedbackerId)))}
                onClose={toggleDialog}>
                <DialogTitle sx={{
                  minWidth: '400px',
                  borderBottom: 'solid 1px ' + green,
                  display: 'flex',
                  justifiyContent: 'space-between'
                }}>
                  <>
                    {feedbacker.firstname && feedbacker.lastname && `${translateText!({
                      de: 'Willkommen',
                      en: 'Welcome'
                    })} ${feedbacker.firstname} ${feedbacker.lastname}`}
                    {!singleLanguage && <LanguageSelector style={{ position: 'absolute', right: 48, top: 14 }}/>}
                    <IconButton
                      aria-label="close"
                      onClick={toggleDialog}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Close/>
                    </IconButton>
                  </>
                </DialogTitle>
                <DialogContent sx={{ whiteSpace: 'pre-wrap', mt: 2 }}>
                  <>{translateText!(localProject.clientBanner)}</>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" onClick={toggleDialog}>{translateText!({
                    de: 'Schliessen',
                    en: 'Close'
                  })}</Button>
                </DialogActions>
              </Dialog>

              <Questionnarie client={getClientById(currentClient.id, localProject)}
                             singleLanguage={singleLanguage}
                             roleWithLang={getRoleWithLang(currentClient)} questions={questions!}
                             answers={localProject!.feedbackers?.[feedbackerId!].clients?.[currentClient.id]?.answers}
                             feedbacker={feedbacker} setData={setData}
                             isSelf={currentClient.role === 'self'}/>

              <Paper sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: grey,
                color: '#fff',
                p: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }} elevation={3}>
                {!(feedbacker.finished ?? false) ?
                  <>
                    <Box sx={{ pr: 2 }}>
                      {translateText!({ de: 'Sie haben ', en: 'You have answered ' })}
                      {getQuestionCount()}
                      {translateText!({ de: ' von ', en: ' of ' })}
                      {getTotalQuestionCount()} {translateText!({ de: ' Fragen beatwortet', en: ' questions' })}.
                    </Box>

                    <Box>
                      {Object.keys(feedbacker.clients).length > 0 && currentClientNumber > 0 &&
                        <Button sx={{ mr: 2 }} variant="outlined" color="primary"
                                onClick={() => setCurrentClientNumber(current => current - 1)}>{translateText!({
                          de: 'Zurück',
                          en: 'Back'
                        })}</Button>
                      }
                      {Object.keys(feedbacker.clients).length > 0 && currentClientNumber < Object.keys(feedbacker.clients).length - 1 ?
                        <Button variant="contained" color="primary"
                                onClick={() => setCurrentClientNumber(current => current + 1)}>
                          {translateText!({ de: 'Weiter zum nächsten Fragebogen', en: 'next questionnarie' })}
                        </Button> :
                        <Button disabled={getTotalQuestionCount() > getQuestionCount()} variant="contained"
                                color="primary" onClick={() => finish()}>{translateText!({
                          de: 'Abschliessen',
                          en: 'Finish'
                        })}</Button>}
                    </Box>
                  </> :
                  <><Box sx={{ width: '60%', textAlign: 'right', mr: 2 }}>
                    {translateText!({
                      de: 'Danke, Sie haben den Fragebogen bereits abgeschlossen.',
                      en: 'Thank you, this questionnarie is closed.'
                    })}
                  </Box>

                    <Box>
                      {Object.keys(feedbacker.clients).length > 0 && currentClientNumber > 0 &&
                        <Button sx={{ mr: 2 }} variant="outlined" color="primary"
                                onClick={() => setCurrentClientNumber(current => current - 1)}>{translateText!({
                          de: 'Zurück',
                          en: 'Back'
                        })}</Button>
                      }
                      {Object.keys(feedbacker.clients).length > 0 && currentClientNumber < Object.keys(feedbacker.clients).length - 1 &&
                        <Button variant="contained" color="primary"
                                onClick={() => setCurrentClientNumber(current => current + 1)}>
                          {translateText!({ de: 'Weiter zum nächsten Fragebogen', en: 'next questionnarie' })}
                        </Button>
                      }
                    </Box>

                  </>}
              </Paper>
            </>}
        </>}
      </>}
    </Box>
  </>
}


export default Feedback
