import { jsPDF } from 'jspdf';
import { Language } from '../model';

const generateImpressum = (pdf:jsPDF, language: Language) => {
  if(language === 'en') {
    // @ts-ignore
    pdf.addImage('/Cover_360 Feedback Impresssum_EN.jpg', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height)
  } else {
    // @ts-ignore
    pdf.addImage('/Cover_360 Feedback Impresssum_DE.jpg', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height)
  }

}

export default generateImpressum
