import { Box, Divider, Paper, Typography } from '@mui/material';
import { green } from '../lib/analysis/analysisUtils';
import LanguageSelector from './languageSelector';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import QuestionLine from './questionLine';
import React, { useContext } from 'react';
import { Client, Feedbacker } from '../lib/model';
import { AuthContext, LanguageContext } from '../lib/context';

type QuestionnnarieProps = {
  client: Client,
  roleWithLang: any,
  singleLanguage: boolean,
  questions: any[],
  answers: any,
  feedbacker: Feedbacker,
  setData: any,
  isSelf: boolean
}
const Questionnarie = ({
                         client,
                         singleLanguage,
                         roleWithLang,
                         questions,
                         answers,
                         feedbacker,
                         setData,
                         isSelf
                       }: QuestionnnarieProps) => {
  const { language, translateText } = useContext(LanguageContext)
  const { user } = useContext(AuthContext)

  const defaultMargin = 3;

  return <Paper
    sx={{
      pt: 3,
      pb: 3,
      width: { xs: '100vw', sm: '100vw', md: '70vw' },
      minHeight: 'calc(100vh - 240px)'
    }}
    key={'client-' + client.id}>
    <Box sx={{ ml: defaultMargin, mr: defaultMargin, display: 'flex' }}>
      <Box>
        <Typography variant="h5" sx={{ color: green }}>
          {translateText!({ de: 'Feedback für', en: 'Feedback for' })}
          : {client.firstname} {client.lastname}
          {!singleLanguage && <LanguageSelector style={{ position: 'absolute', right: 24, top: 24 }}/>}
        </Typography>
        <Box sx={{ pb: 3, fontSize: 12 }}>
          {translateText!({ de: 'Sie geben Feedback in der Rolle als ', en: 'Your role as feedback provider is: ' })}
          {feedbacker.gender === 'w'? roleWithLang.name.she: roleWithLang.name.he }</Box>
      </Box>
    </Box>
    <Divider/>
    <Box sx={{
      backgroundColor: green,
      px: 3,
      py: 1,
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    }}>
      <Box>{roleWithLang.phraseStart[client.gender === 'w' ? 'she' : 'he']}</Box>
      {language === 'en' ?
        <Box>0 = can't tell<br/>
          1 = disagree<br/>
          2 = rather disagree<br/>
          3 = neutral<br/>
          4 = rather agree<br/>
          5 = agree</Box> :
        <Box>0 = kann ich nicht beurteilen<br/>
          1 = trifft nicht zu<br/>
          2 = trifft eher nicht zu<br/>
          3 = teils teils<br/>
          4 = trifft eher zu<br/>
          5 = trifft zu
        </Box>
      }
    </Box>
    <Divider/>
    {questions &&
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList height={height - 180} width={width} itemCount={Object.keys(questions).length}
                         itemSize={150}>
            {({ index, style }) =>
              <QuestionLine
                readonly={(feedbacker.finished ?? false) || user?.['uid'] !== feedbacker.id}
                style={style}
                key={client.id + Object.values(questions)[index].id}
                question={Object.values(questions)[index]}
                clientGender={client.gender}
                score={answers?.[Object.values(questions)[index].id]?.score}
                remark={answers?.[Object.values(questions)[index].id]?.remark}
                setData={(value) => setData(`feedbackers.${feedbacker.id}.clients.${client.id}.answers.${Object.values(questions)[index].id}`, value)}
                isSelf={isSelf}
              />
            }
          </FixedSizeList>
        )}
      </AutoSizer>
    }
  </Paper>
}

export default Questionnarie
