import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Question } from '../lib/model';
import { LanguageContext } from '../lib/context';


type Props = {
  question: Question,
  clientGender: string
  score: number | undefined,
  remark: string | undefined,
  setData: (value: any) => void
  style: any,
  readonly: boolean
  isSelf?:boolean
}

const QuestionLine = ({ question, clientGender, score, remark, setData, style, readonly, isSelf= false }: Props) => {

  const [localRemark, setLocalRemark] = useState<string | undefined>(remark)
  const {language, translateText} = useContext(LanguageContext)

  const getQuestion = (question: Question) => {

    if(language === 'en' && (question.en?.me || question.en?.she || question.en?.he)) {
      return isSelf ? question.en.me : clientGender === 'w' ? question.en.she : question.en.he;
    }
    if(question.de) {
      return isSelf ? question.de.me : clientGender === 'w' ? question.de.she : question.de.he;
    }
    return '-'
  }

  const addRemark = () => {
    setLocalRemark(' ')
  }

  const remarkChange = (e: any) => {
    setLocalRemark(e.target.value.trimStart())
    if(!e.target.value){
      score ? setData({score, remark: e.target.value}) : setData({remark: e.target.value})
    }
  }

  const saveRemark = (e:any) => {
    setLocalRemark(e.target.value.trimStart())
    score ? setData({score, remark: e.target.value}) : setData({remark: e.target.value})
  }

  return <div style={style}><Box sx={{ m: 3 }}>
    <Box sx={{ mb: 1, display: 'flex', justifyItems: 'start' }}>
      <Box sx={{ minWidth: '40px' }}>{question.questionNumber}. </Box>
      <Box sx={{ pr: 3 }}>{getQuestion(question)}</Box>
      <RadioGroup
        sx={{ display: 'flex', justifyContent: 'center', ml: 'auto', minWidth: '175px' }}
        row
        value={score ?? ''}
        onChange={(e) => {
          localRemark ? setData({ score: e.target.value, remark: localRemark }) : setData({ score: e.target.value })
        }}>
        <CustomFormControlLabel value="0" readonly={readonly}/>
        <Divider orientation="vertical" flexItem/>
        <CustomFormControlLabel value="1" readonly={readonly}/>
        <CustomFormControlLabel value="2" readonly={readonly}/>
        <CustomFormControlLabel value="3" readonly={readonly}/>
        <CustomFormControlLabel value="4" readonly={readonly}/>
        <CustomFormControlLabel value="5" readonly={readonly}/>
      </RadioGroup>
    </Box>
    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
      {localRemark ? <TextField
        disabled={readonly}
        label={translateText!({de: 'Kommentar', en: 'comment'})}
        value={localRemark}
        size="small"
        variant="outlined"
        fullWidth
        onBlur={saveRemark}
        onInput={remarkChange}
      />:
      <Button sx={{width: '180px'}} variant="outlined" onClick={addRemark} disabled={readonly}>
        {translateText!({de: 'Kommentar hinzufügen', en: 'add comment'})}
      </Button>}
    </Box>

  </Box>
    <Divider/>
  </div>
}

export default QuestionLine


const CustomFormControlLabel = ({ value, readonly }: { value: string, readonly: boolean }) => {
  return (
    <FormControlLabel disabled={readonly} control={<Radio size="small" sx={{ p: 0, m: 0 }}/>} label={value}
                      value={value}
                      labelPlacement="top"/>
  )
}
