import { Context, } from '../lib/model';
import { collection, query, onSnapshot, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { firebaseDb } from '../lib/firebase';
import { useEffect, useState } from 'react';


export const useContexts = (): any => {

  const [contexts, setContexts] = useState<Context[]>([])

  const updateContext = (newContext: Context) => {
    const data: Partial<Context> =  {... newContext};
    delete data.id;
    setDoc(doc(firebaseDb, 'contexts', newContext.id!), data)
  }

  const deleteContext = (ContextToDelete: Context) => {
    deleteDoc(doc(firebaseDb,'contexts', ContextToDelete.id))
  }

  useEffect(() => {
      onSnapshot(query(collection(firebaseDb, 'contexts')), (snapshot => {
        const temp: Context[] = []
        snapshot.forEach(doc => {
          const data = doc.data() as Pick<Context, 'de' | 'en'>
          temp.push({ ...data, id: doc.id })
        })
        setContexts(temp)
      }));
    }
    , [])

  return { contexts , updateContext, deleteContext}
}
