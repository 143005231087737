import React, { useState } from 'react'
import Header from '../components/header';
import { useParams } from 'react-router-dom';
import PersonImport from '../components/personImport';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useProject } from '../hooks/useProject';
import QuestionImport from '../components/questionImport';
import PersonList from '../components/personList';
import QuestionList from '../components/questionList';

const Project = () => {
  const { projectId } = useParams()
  const { project } = useProject(projectId)

  const [activeTab, setActiveTab] = useState(0)

  return <>
    <Header/>
    {project && <Box sx={{ m: 2 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {project.name}
      </Typography>
      <Box>
        <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
          <Tab label="Personen"/>
          <Tab label="Fragen"/>
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && ((!project.feedbackers || !project.clients) ? <PersonImport project={project}/> :
            <PersonList project={project}/>)}
          {activeTab === 1 && (!project.questions ? <QuestionImport project={project}/> : <QuestionList project={project}/>)}
        </Box>
      </Box>
    </Box>}
  </>
}

export default Project
