import React, { useState } from 'react'
import Header from '../components/header';
import { Box, Tab, Tabs } from '@mui/material';
import Roles from '../components/roles';
import Contexts from '../components/contexts';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0)

  return <>
    <Header />
    <Box sx={{p:2}}>
      <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
        <Tab label="Rollen" />
        <Tab label="Themen" />
      </Tabs>
      {activeTab === 0 &&<Roles />}
      {activeTab === 1 && <Contexts />}
      {activeTab === 2 && <div>Benutzer</div>}
    </Box>
  </>
}

export default  Settings
