import { useContext, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { green, grey } from '../lib/analysis/analysisUtils';
import { useRoles } from '../hooks/useRoles';
import { Context, Role } from '../lib/model';
import { LanguageContext } from '../lib/context';
import { useContexts } from '../hooks/useContexts';

export type SpiderData = { context: string, self: number, others: number }[]
type Props = {
  data: SpiderData
  addCanva: (canva: React.RefObject<HTMLCanvasElement>, id: string) => void
}
const SpiderChart = ({ data, addCanva }: Props) => {

  const canvas = useRef<HTMLCanvasElement>(null);
  const { roles } = useRoles()
  const { contexts } = useContexts()
  const { translateText, language } = useContext(LanguageContext)


  const meColor = roles && roles.find((role: Role) => role.id === 'self') ? roles.find((role: Role) => role.id === 'self').color : green;
  const othersColor = roles && roles.find((role: Role) => role.id !== 'self') ? roles.find((role: Role) => role.id !== 'self').color : grey;


  useEffect(() => {
    addCanva(canvas, 'a-spiderChart')
    if (translateText && contexts.length > 0 && roles) {
      const chartData = {
        labels: data.map(element => {
          const usedContext = contexts.find((currentContext: Context) => currentContext.id === element.context)
          return translateText({ de: usedContext?.de ?? element.context, en: usedContext?.en ?? element.context })
        }),
        datasets: [{
          label: translateText({ de: 'Selbsteinschätzung', en: 'self-assessment' }),
          data: data.map(element => element.self),
          fill: true,
          legend: 'test',
          backgroundColor: meColor + 33,
          borderColor: meColor + 'aa',
          pointBackgroundColor: meColor,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: meColor + 99
        }, {
          label: translateText({ de: 'Fremdeinschätzung', en: 'external assessment' }),
          data: data.map(element => element.others),
          fill: true,
          backgroundColor: othersColor + 33,
          borderColor: othersColor + 'aa',
          pointBackgroundColor: othersColor,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: othersColor + 99
        }]
      }

      const options = {
        devicePixelRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 13
              }
            },
            suggestedMax: 5,
            suggestedMin: 0,
          }
        },
        plugins: {
          legend: {
            position: 'top'
          },
          datalabels: {
            display: false,
          }
        }
      };

      if (canvas.current) {
        const chart = new Chart(canvas.current, {
          type: 'radar',
          data: chartData,
          // @ts-ignore
          options: options,
          plugins: [ChartDataLabels]
        });

        return () => {
          chart.destroy();
        };
      }
    }

  }, [data, translateText, contexts, language])

  return <>
    {translateText &&
      <div style={{ width: '780px', height: '500px' }}>
        <canvas ref={canvas}
                id="a-spiderChart"
                data-title={translateText({ de: 'Gesamtübersicht', en: 'General overview' })}/>
      </div>
    }
  </>
}

export default SpiderChart
