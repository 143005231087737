import { jsPDF } from 'jspdf';
import generateFrontpage from './pdf/generateFrontpage';
import generateImpressum from './pdf/generateImpressum';
import { darkGrey, grey } from './analysis/analysisUtils';
import { Client, LangElement, Language } from './model';

const pageMargin = { top: 50, left: 45, bottom: 0, right: 45 }
type TocElement = { text: string, page: number }
const start = pageMargin.top + 20
let lastPosition: number;

export const translateText = (language: Language, multilingualText: LangElement): string => {
  if(language === 'de' && multilingualText.de){
    return multilingualText.de
  }
  if ((!multilingualText.de || language === 'en') && multilingualText.en){
    return multilingualText.en
  }
  if(multilingualText.de){
    return multilingualText.de
  }
  return ''
}

export const generatePDF = async (canvas: React.RefObject<HTMLCanvasElement>[], client: Client, showFrontpage: boolean, description: string, language: Language): Promise<any> => {

  const pdf = new jsPDF('p', 'px', 'a4', true);
  const toc: TocElement[] = []

  if (showFrontpage) {
    generateFrontpage(pdf, client, language)
    addPage(pdf, client)
  } else {
    addHeader(pdf)
    addFooter(pdf, client)
    lastPosition = start
  }


  if (description) {
    await addDescription(pdf, client, toc, description, showFrontpage, language)
  }

  addDiagrames(pdf, canvas, toc, client, language)

  addPage(pdf, client)
  generateImpressum(pdf, language)

  addToc(pdf, showFrontpage, client, toc, language)

  return pdf.save(`${client.firstname}_${client.lastname}.pdf`, { returnPromise: true });
}

const addDiagrames = (pdf: jsPDF, canvas: React.RefObject<HTMLCanvasElement>[], toc: TocElement[], client: Client, language: Language) => {
  const contentWidth = pdf.internal.pageSize.width - pageMargin.right - pageMargin.left

  canvas.forEach((canva: React.RefObject<HTMLCanvasElement>) => {
    if (!canva.current) {
      console.log('canva is null')
      return
    }

    const ueberschrift = canva.current.dataset.title

    if (canva.current.id === 'a-spiderChart') {
      const imgData = canva.current.toDataURL('image/png')
      const width = pdf.internal.pageSize.width - pageMargin.left - pageMargin.right
      const factor = width / canva.current.width
      const height = canva.current.height * factor

      if (ueberschrift) {
        addUeberschrift(pdf, ueberschrift, toc, client)
      }
      const y = lastPosition
      pdf.addImage(imgData, 'JPEG', pageMargin.left, y, width, height)
      lastPosition = y + height + 20
    } else {
      const imgData = canva.current.toDataURL('image/png')
      const spaceForText = pdf.internal.pageSize.width / 3 - 10
      const width = pdf.internal.pageSize.width - pageMargin.left - pageMargin.right - spaceForText
      const factor = width / canva.current.width
      const height = canva.current.height * factor

      let remarksHeight = 0
      let remarks: string[] = []
      if (canva.current.dataset.remarks) {
        remarks = JSON.parse(canva.current.dataset.remarks) as string[]
        if (remarks.length > 0) {
          remarksHeight += 20 + 12
          remarks.forEach(remark => {
            const textLines = pdf.setFont('Helvetica')
              .setFontSize(12)
              .setTextColor(darkGrey)
              .splitTextToSize(remark, width)
            remarksHeight += (textLines.length * 0.5) + 12
          })
        }
      }

      if (lastPosition + height + remarksHeight > pdf.internal.pageSize.height - pageMargin.bottom - pageMargin.top) {
        addPage(pdf, client)
      }

      if (ueberschrift) {
        addUeberschrift(pdf, ueberschrift, toc, client)
      }

      const y = lastPosition
      if (canva.current.dataset.text) {
        addMultilineText(pdf, `${canva.current.dataset.text}`, pageMargin.left, y, spaceForText - 10, 15)
      }

      pdf.addImage(imgData, 'JPEG', pageMargin.left + spaceForText, y, width, height)

      lastPosition = y + height + 10

      if (remarks.length > 0) {
        pdf.setFont('Helvetica', 'bold')
        pdf.text(translateText(language, {de:'Kommentare', en: 'Comments'}), pageMargin.left, lastPosition)
        pdf.setFont('Helvetica', 'normal')

        lastPosition += 12

        remarks.forEach(remark => {
          lastPosition += addMultilineText(pdf, remark.trimStart(), pageMargin.left, lastPosition, contentWidth, 0) * 12
        })
      }
      lastPosition += 10
    }

  })
}

const addDescription = async (pdf: jsPDF, client: Client, toc: TocElement[], description: string, showFrontpage: boolean, language: Language) => {
  const pageCountBeforeDescription = pdf.internal.pages.length
  addUeberschrift(pdf, translateText(language, {de:'Einleitung', en: 'Introduction' }), toc, client)
  const descriptions = description.split('<p>NEWPAGE</p>')
  let index = 0
  for(let partDescription of descriptions) {
    partDescription = `<div style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; font-size: 18px; line-height: 22px; color:${grey}">${partDescription}</div>`
    await pdf.html(partDescription, {
      margin: [pageMargin.top + 5, pageMargin.right, pageMargin.bottom + 60, pageMargin.left],
      autoPaging: 'text',
      x: 0,
      y:index === 0 ? 20 + (showFrontpage ? (pdf.internal.pageSize.height - 117) : 0)
        : ((pdf.internal.pages.length-1) * (pdf.internal.pageSize.height - 117)),
      html2canvas: { scale: 0.5 },
      width: pdf.internal.pageSize.width, //target width in the PDF document
      windowWidth: 700 //window width in CSS pixels
    })
    index = index + 1
  }
  const pageCountAfterDescription = pdf.internal.pages.length
  const pageToAdd = pageCountAfterDescription - pageCountBeforeDescription

  for (let i = 0; i < pageToAdd; i++) {
    const activePage = pageCountBeforeDescription + i
    pdf.setPage(activePage)
    addHeader(pdf)
    addFooter(pdf, client, activePage + 1)
  }

  addPage(pdf, client)
}

const addToc = (pdf: jsPDF, showFrontpage: boolean, client: Client, toc: TocElement[], language: Language) => {
  let pageNumberToAdd = 1
  if (showFrontpage) {
    pageNumberToAdd = 2
  }

  pdf.insertPage(pageNumberToAdd)
  addFooter(pdf, client, pageNumberToAdd)
  addHeader(pdf)
  addUeberschrift(pdf, translateText(language, {de: 'Inhaltsverzeichnis', en: 'Table of contents'}), toc, client, pageNumberToAdd, false)
  lastPosition += 8
  toc.forEach((tocElement, index) => {
    pdf.text(tocElement.text, pageMargin.left, lastPosition + (index * 12))
    pdf.text(tocElement.page.toString(), pdf.internal.pageSize.width - pageMargin.right - pdf.getTextWidth(tocElement.page.toString()), lastPosition + (index * 12))
  })

}


const addFooter = (pdf: jsPDF, client: Client, localPageNumber: number = pdf.internal.pages.length) => {
  const y = pdf.internal.pageSize.height - pageMargin.top - pageMargin.bottom
  pdf.setDrawColor(grey)
  pdf.line(pageMargin.left,
    y,
    pdf.internal.pageSize.width - pageMargin.left,
    y)

  pdf.setFontSize(10)
  pdf.setTextColor(grey)
  pdf.text(`${client.firstname} ${client.lastname}`, pageMargin.left, y + 10)

  const year = new Date().getFullYear().toString()
  pdf.text(year, (pdf.internal.pageSize.width / 2) - (pdf.getTextWidth(year) / 2), y + 10)

  const x = pdf.internal.pageSize.width - pageMargin.right - pdf.getTextWidth(localPageNumber.toString())
  pdf.text(localPageNumber.toString(), x, y + 10)
}

const addHeader = (pdf: jsPDF) => {
  const w = 75
  const x = pdf.internal.pageSize.width - pageMargin.right - w - 3

  // @ts-ignore
  pdf.addImage('/skillsgarden-logo-rgb.png', x, 30, w, 15)

  pdf.setFontSize(10)
  pdf.setTextColor(grey)
  pdf.text('360° Feedback', pageMargin.left, 45)

  pdf.setDrawColor(grey)
  pdf.line(pageMargin.left,
    pageMargin.top,
    pdf.internal.pageSize.width - pageMargin.left,
    pageMargin.top,)
}

const addUeberschrift = (pdf: jsPDF, text: string, toc: TocElement[], client: Client, pageNumber: number = pdf.internal.pages.length, addToToc = true): boolean => {
  if (!toc.some(tocElement => tocElement.text === text)) {
    if (addToToc && lastPosition > 100) {
      addPage(pdf, client)
      pageNumber = pdf.internal.pages.length
    }

    lastPosition = start

    addToToc && toc.push({ text, page: pageNumber })

    pdf.setFont('Helvetica', 'bold')
    pdf.setFontSize(18)
    pdf.text(text, pageMargin.left, lastPosition)
    pdf.setFont('Helvetica', 'normal')
    pdf.setFontSize(12)

    lastPosition += 12
    return true
  }
  return false
}

const addMultilineText = (pdf: jsPDF, text: string, x: number, y: number, width: number, offset = 0): number => {
  const textLines = pdf.setFont('Helvetica')
    .setFontSize(12)
    .setTextColor(grey)
    .splitTextToSize(text, width)
  pdf.text(textLines, pageMargin.left, y + offset)
  offset += (textLines.length + 0.5) * 12
  return textLines.length
}

const addPage = (pdf: jsPDF, client: Client) => {
  pdf.addPage()
  addHeader(pdf)
  addFooter(pdf, client)
  lastPosition = start
}

