import React, { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { AppBar, Box, Button, IconButton } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Logout } from '@mui/icons-material';
import { AuthContext } from '../lib/context';
import { firebaseAuth } from '../lib/firebase';
import {
  Link, useLocation,
} from 'react-router-dom';

const Header = () => {
  const { user, isAdmin } = useContext(AuthContext)
  const location = useLocation();

  const adminNavItems = ['Projekte', 'Einstellungen']
  const navItems = ['Admin']

  return <AppBar position="sticky">
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <img src="/skillsgarden.ch-logo-white.png" height="34px" alt="Logo von Skillsgaren"/>
      </Box>
      {!user && <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {navItems.map((item) => (<span key={'nav' + item}>
            {location.pathname !== `/${item.toLocaleLowerCase()}` &&
              <Button sx={{ color: '#fff' }} to={`/${item.toLocaleLowerCase()}`} component={Link}>
                {item}
              </Button>
            }</span>
        ))}
      </Box>}
      {user && <><Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {isAdmin && adminNavItems.map((item) => (
          <span key={'admin' + item}>
            <Button sx={location.pathname === `/${item.toLocaleLowerCase()}` ? {
              color: '#fff',
              fontWeight: 'bold'
            } : { color: '#fff' }} to={`/${item.toLocaleLowerCase()}`} component={Link}>
              {item}
            </Button>
          </span>
        ))}
      </Box><IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={() => {
          sessionStorage.removeItem('Auth Token')
          signOut(firebaseAuth);
          window.location.reload();
        }}>
        <Logout/>
      </IconButton></>
      }
    </Toolbar>
  </AppBar>
}

export default Header
