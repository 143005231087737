import { AnalysisBasicData, Language, Project, Question, Role } from '../model';

export const generateAnalysisBasic = (project: Project, roles: Role[], clientId: string): AnalysisBasicData => {

  if(!project ||  !project.questions || !project.feedbackers){
    return {}
  }

  //prepare all questions and contextes
  const analysisBasic = Object.values(project.questions).reduce(
    (analysisBasic: AnalysisBasicData, question: Question) => {
      analysisBasic[question.id] = {context: question.context, roles:{}}
      return analysisBasic
  }, {})


  const feedbackersForClient = Object.values(project.feedbackers)
    .filter(feedbacker => Object.keys(feedbacker.clients)
      .some(currentClientId => currentClientId === clientId))

  //add all results
  feedbackersForClient.forEach(feedbacker => {
    const answers = feedbacker.clients[clientId].answers
    const role: string = feedbacker.clients[clientId].role
    if(answers){
      Object.entries(answers).forEach(([answerId, answer]) => {
        if(!analysisBasic[answerId].roles[role]){
          analysisBasic[answerId].roles[role] = []
        }
        analysisBasic[answerId].roles[role] = [...analysisBasic[answerId].roles[role], answer.score]
      })
    }
  })

  return analysisBasic
}
