// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from 'firebase/firestore';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkK12CSUV2ZZRv316yfVrqziE8-Fs_QdI",
  authDomain: "realfeedback-cbea3.firebaseapp.com",
  projectId: "realfeedback-cbea3",
  storageBucket: "realfeedback-cbea3.appspot.com",
  messagingSenderId: "705901982089",
  appId: "1:705901982089:web:1468e3c53f119a3ceeb158",
  measurementId: "G-X5014H5B66"
};

// Initialize Firebase
let app;
export const firebaseApp = !app ? app = initializeApp(firebaseConfig) : app;

//const analytics = getAnalytics(firebaseApp);

export const firebaseAuth = getAuth(firebaseApp)
export const firebaseDb = getFirestore(firebaseApp)

const functions = getFunctions(app);
export const createUserCall = httpsCallable(functions, 'createUser')
export const sendMailCall = httpsCallable(functions, 'sendEmail')
export const sendCodeCall = httpsCallable(functions, 'sendCode')
export const updatePasswortCall = httpsCallable(functions, 'updatePasswort')
export const updateMail = httpsCallable(functions, 'updateMail')
