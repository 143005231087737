import { Project } from '../lib/model';
import { doc, onSnapshot, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { firebaseDb } from '../lib/firebase';
import { useEffect, useState } from 'react';
import _ from 'lodash';


export const useProject = (projectId?: string): any => {

  const [project, setProject] = useState<Project | null>(null)
  const [singleProject, setSingleProject] = useState<Project | null>(null)

  const updateProject = (newProject: Partial<Project>) => {
    const data: Partial<Project> = _.cloneDeep(newProject);
    delete data.id;
    setDoc(doc(firebaseDb, 'projects', newProject.id!), data)
  }

  const deleteProject = (projectToDelete: Project) => {
    deleteDoc(doc(firebaseDb,'projects', projectToDelete.id))
  }

  useEffect(() => {
      let unsubscrib = () => {}
      if(projectId){
      unsubscrib = onSnapshot(doc(firebaseDb, 'projects', projectId), (snapshot => {
        const data = snapshot.data() as Project;
        setProject({ ...data, id: snapshot.id })
      }));
    }
      return () => unsubscrib()
    }
    , [projectId])

  useEffect(() => {
    if(projectId){
    getDoc(doc(firebaseDb, 'projects', projectId)).then(docSnap => {
      if(docSnap.exists()){
        const data = docSnap.data() as Project
        setSingleProject({...data, id: projectId})
      }
    })
    }
  }, [projectId])

  return { project ,singleProject,  updateProject, deleteProject}
}
