import * as React from 'react';
import { AuthContext } from './context';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';


export const Protect = ({ children, fallback}: {children: JSX.Element, fallback:string})  => {

  const { user } = useContext(AuthContext)

  if (!user && !sessionStorage.getItem('Auth Token')) {
    return <Navigate to={fallback} />
  }
  return children
}

