import { useContext, useEffect, useRef } from 'react';
import Chart, { ChartData } from 'chart.js/auto';
import { darkGrey} from '../lib/analysis/analysisUtils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LanguageContext } from '../lib/context';
import { useRoles } from '../hooks/useRoles';
import { useContexts } from '../hooks/useContexts';
import { Context, Role } from '../lib/model';


export type HorizontalBarChartData = { role: string, mean: number, sd: number }[]
type Props = {
  data: HorizontalBarChartData,
  id: string,
  addCanva: (canva: React.RefObject<HTMLCanvasElement>, id: string) => void,
  question: string,
  remarks: string[]
  contextId: string
}
const HorizontalBarChart = ({ data, id, addCanva, question, remarks, contextId }: Props) => {

  const canvas = useRef<HTMLCanvasElement>(null);
  const { roles } = useRoles()
  const { contexts } = useContexts()
  const { translateText, language } = useContext(LanguageContext)


  const AdditionalLabels = {
    id: 'AdditionalLabels',
    afterDatasetDraw(chart: Chart) {
      const { ctx, scales: { x, y } } = chart;
      Object.values(data).forEach((value, index) => {
        if (value.mean > 0) {
          ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif'
          ctx.fillStyle = '#fff'
          ctx.textBaseline = 'middle'
          ctx.fillText('+/- ' + value.sd, x.getPixelForValue(0.1), y.getPixelForValue(index))
        }
      })
    }
  }

  useEffect(() => {
    addCanva(canvas, `horizontalBarChart-${id}`)
  }, [])


  useEffect(() => {
    if (roles.length > 0 && translateText) {
      const colors = data.map(element => {
        const usedRole = roles.find((currentRole: Role) => currentRole.id === element.role)
        return usedRole?.color ?? '#aaaaaa'
      })

      let chartData: ChartData;
      chartData = {
        labels: data.map(element => {
          const usedRole = roles.find((currentRole: Role) => currentRole.id === element.role)
          return translateText({ de: usedRole?.de?.name.he ?? element.role, en: usedRole?.en?.name.he ?? element.role })
        }),
        datasets: [
          {
            data: data.map(element => element.mean),
            order: 0,
            type: 'bar',
            backgroundColor: colors
          },
        ]
      };

      const options = {
        animation: false,
        indexAxis: 'y',
        devicePixelRatio: 2.5,
        scales: {
          y: {
            ticks: {
              font: {
                size: 15,
                family: 'Helvetica Neue'
              },
              color: darkGrey
            },
          },
          x: {
            suggestedMax: 5,
            ticks: {
              font: {
                size: 15,
                family: 'Helvetica Neue'
              },
              color: darkGrey
            },
          }
        },
        plugins: {
          title: {
            display: false,
            font: {
              size: 20,
              family: 'Helvetica Neue'
            }
          },
          legend: {
            display: false
          },
          datalabels: {
            color: '#ffffff',
            display: (context: any) => context.dataset!.type === 'bar',
            font: {
              weight: 'bold',
              size: 16
            },
            anchor: 'end',
            align: 'left',
          }
        }
      };
      if (canvas.current) {
        const chart = new Chart(canvas.current, {
          data: chartData,
          // @ts-ignore
          options: options,
          plugins: [ChartDataLabels, AdditionalLabels]
        });

        return () => {
          chart.destroy();
        };
      }
    }
  }, [data, roles, language, contexts]);

  const getContext = () => {
    const usedContext = contexts.find((currentContext: Context) => currentContext.id === contextId)
    return translateText && usedContext && translateText({de: usedContext.de, en: usedContext.en})
  }

  return <>
    {translateText && contexts.length > 0 &&
    <div style={{ width: '500px' }}>
      <canvas ref={canvas} data-text={question} data-remarks={JSON.stringify(remarks)}
              data-title={getContext()}/>
    </div>
    }
  </>
}

export default HorizontalBarChart
