import { useContext, useState } from 'react';
import { generatePDF } from '../lib/pdfCreator';
import { Client, Language } from '../lib/model';
import { LanguageContext } from '../lib/context';

export const usePDF = () => {
  const [canvas, setCanvas] = useState<{ canva: React.RefObject<HTMLCanvasElement>, id: string }[]>([])

  const { language } = useContext(LanguageContext)

  const addCanva = (canva: React.RefObject<HTMLCanvasElement>, id: string) => {
    setCanvas((oldCanvas) =>
      oldCanvas.filter(oldCanva => oldCanva.id !== id).concat({ canva, id })
        .sort((a, b) => {
          if (a.id.includes('id-') && b.id.includes('id-')) {
            return 1
          }
          return a.id.localeCompare(b.id)
        })
    )
  }

  const generatePdf = (showFrontpage: boolean, client: Client, description = '') => {
    const canvasElement = canvas.map(canva => canva.canva)
    return generatePDF(canvasElement, client, showFrontpage, description, language as Language)
  }

  return { canvas, addCanva, generatePdf }
}
