export type RolePerLang = { name: { he: string, she: string } , phraseStart: { he: string, she: string } }
export type Role = { id: string, de: RolePerLang, en: RolePerLang, color: string}

export type Context = { id: string, de: string, en: string }

export type Project = {
  id: string,
  active: boolean,
  name: string,
  created: Date,
  clientBanner: LangElement,
  clients?: { [key: string]: Client },
  feedbackers?: { [key: string]: Feedbacker },
  questions?: { [key: string]: Question }
}

export type LangElement = { de?: string, en?: string }
export type Client = { id: string, firstname: string, lastname: string, gender: Gender, mail: string }
export type Feedbacker = {
  id: string,
  mail: string,
  gender: Gender,
  firstname: string,
  lastname: string,
  finished?: boolean,
  clients: {
    [key: string]: ClientRef
  }
}

export  type ClientRef = {
  id: string,
  role: string,
  answers?: { [key: string]: { score: number, remark?: string } }
}
export type Question = { id: string, questionNumber: string, context: string, de?: GenderTexts, en?: GenderTexts }
export type Gender = 'w' | 'm'
export type GenderTexts = { he?: string, she?: string, me?: string }
export type Language = 'de' |'en'


export type AnalysisBasicData = {
  //QuestionID
  [key: string]: AnalysisBasicDataElement
}

export type AnalysisBasicDataElement = {
  context: string,
  roles: {
    [key: string]: number[], // all results
  }
}

export const BASEPATH = 'https://www.realfeedback.ch/'//'https://tomra.i234.me/'//'http://localhost:3000'
