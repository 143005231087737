import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as _ from 'lodash'

import { useRoles } from '../hooks/useRoles';
import { useState } from 'react';
import { Role } from '../lib/model';

const Roles = () => {
  const { roles, updateRole, deleteRole } = useRoles()
  const [showFormModal, setShowFormModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [role, setRole] = useState<Partial<Role>>({})

  const toggleModal = () => {
    setShowFormModal(!showFormModal)
  }

  const setData = (field: string, content: string) => {
    const temp = _.cloneDeep(role)
    _.set(temp, field, content)
    setRole(temp)
  }

  const openEdit = (roleToEdit: Role) => {
    setRole(roleToEdit);
    setIsUpdate(true)
    setShowFormModal(true)
  }

  const openCreateNew = () => {
    setRole({});
    setIsUpdate(false)
    setShowFormModal(true)
  }

  const saveRole = () => {
    updateRole(role)
    setShowFormModal(false)
  }

  const deleteRow = (role: Role) => {
    if (window.confirm(`Wollen Sie ${role.id} wirklich löschen ?`)) {
      deleteRole(role)
    }
  }

  const columns: GridColDef[] = [{ field: 'id', headerName: 'Referenz', width: 90 },
    {
      field: 'deName', headerName: 'DE Bezeichnung', flex: 0.2,
      renderCell: (params) => {
        return (<Box>
          <Box>Er: {params.row.de?.name?.he ?? '-'}</Box>
          <Box>Sie: {params.row.de?.name?.she ?? '-'}</Box>
        </Box>)
      }
    },
    {
      field: 'dePhraseStart', headerName: 'DE Satz startet mit...', flex: 0.5,
      renderCell: (params) => {
        return (<Box>
          <Box>Er: {params.row.de?.phraseStart?.he ?? '-'}</Box>
          <Box>Sie: {params.row.de?.phraseStart?.she ?? '-'}</Box>
        </Box>)
      }
    },
    {
      field: 'enName', headerName: 'EN Bezeichnung', flex: 0.2,
      renderCell: (params) => {
        return (<Box>
          <Box>Er: {params.row.en?.name?.he ?? '-'}</Box>
          <Box>Sie: {params.row.en?.name?.she ?? '-'}</Box>
        </Box>)
      }
    },
    {
      field: 'enPhraseStart', headerName: 'EN Satz startet mit...', flex: 0.5,
      renderCell: (params) => {
        return (<Box>
          <Box>Er: {params.row.en?.phraseStart?.he ?? '-'}</Box>
          <Box>Sie: {params.row.en?.phraseStart?.she ?? '-'}</Box>
        </Box>)
      }
    },
    {
      field: 'color', headerName: 'Farbe in hex mit #',
      renderCell: (params) => {
        return (<>{params.row.color ?? '-'}</>)
      }
    },
    {
      field: 'actions', headerName: 'Aktionen', width: 220, renderCell: (params) => {
        return (<>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => openEdit(params.row)}
              variant="outlined"
            >
              Bearbeiten
            </Button>
            <Button
              color="error"
              onClick={() => deleteRow(params.row)}
              variant="contained"
            >
              Löschen
            </Button>
          </>
        );
      }
    }]

  return <Box sx={{ mt: 2 }}>
    <Typography variant="h2">
      Rollen
    </Typography>
    <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'end' }}>
      <Button variant="contained" color="primary" onClick={openCreateNew}>
        Neue Rolle erstellen
      </Button>
    </Box>
    <DataGrid
      columns={columns}
      autoHeight
      rows={roles}
      checkboxSelection
      disableSelectionOnClick
    />
    <Dialog
      open={showFormModal}
      onClose={toggleModal}
    >
      <DialogTitle>
        {role ? 'Rolle bearbeiten' : 'Neue Rolle'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', mt: 2 }}>
          <TextField
            sx={{ width: '100%' }}
            id="id"
            label="Referenz"
            defaultValue={role.id}
            disabled={isUpdate}
            onChange={(event) => setData('id', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mt: 2 }}
            id="id"
            label="Farbe"
            defaultValue={role.color}
            onChange={(event) => setData('color', event.target.value)}
          />
          <Typography variant="h6" component="h1" sx={{ mt: 2, mb: 2 }}>
            Deutsch
          </Typography>
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Bezeichnung Sie"
            defaultValue={role.de?.name.she}
            onChange={(event) => setData('de.name.she', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Bezeichnung Sie"
            defaultValue={role.de?.name.he}
            onChange={(event) => setData('de.name.he', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Satzanfang Sie"
            defaultValue={role.de?.phraseStart?.she}
            onChange={(event) => setData('de.phraseStart.she', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Satzanfang Er"
            defaultValue={role.de?.phraseStart?.he}
            onChange={(event) => setData('de.phraseStart.he', event.target.value)}
          />
          <Typography variant="h6" component="h1" sx={{ mt: 2, mb: 2 }}>
            Englisch
          </Typography>
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Bezeichnung Sie"
            defaultValue={role.en?.name.she}
            onChange={(event) => setData('en.name.she', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Bezeichnung Er"
            defaultValue={role.en?.name.he}
            onChange={(event) => setData('en.name.he', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Satzanfang Sie"
            defaultValue={role.en?.phraseStart?.she}
            onChange={(event) => setData('en.phraseStart.she', event.target.value)}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Satzanfang Er"
            defaultValue={role.en?.phraseStart?.he}
            onChange={(event) => setData('en.phraseStart.he', event.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={toggleModal}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={saveRole}>Speichern</Button>
      </DialogActions>
    </Dialog>
  </Box>
}

export default Roles
