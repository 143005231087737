import { Project } from '../lib/model';
import { Button } from '@mui/material';
import { ChangeEvent, Fragment, useRef } from 'react';
import { parseQuestions } from '../lib/parser';
import { useProject } from '../hooks/useProject';

type Props = {
  project: Project
}
const QuestionImport = ({ project }: Props) => {

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const { updateProject } = useProject()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      parseQuestions(file).then( result =>
        {
          project.questions = result.questions
          updateProject(project)
        }
      )
    }
  }

  return <>
    <Fragment>
      <input
        ref={uploadInputRef}
        type="file"
        accept="text/csv"
        style={{ display: 'none' }}
        onChange={onChange}
      />
      <Button
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
        variant="contained"
      >
        Fragen Upload
      </Button>
    </Fragment>
  </>
}

export default QuestionImport
