import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BASEPATH, Client, ClientRef, Feedbacker, Project } from '../lib/model';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check'
import { sendMailCall } from '../lib/firebase';
import { useProject } from '../hooks/useProject';
import PersonDetails from './personDetails';

type Props = {
  project: Project
}

const PersonenList = ({ project }: Props) => {

  const [clients, setClients] = useState<Client[]>([])
  const [feedbackers, setFeedbackers] = useState<Feedbacker[]>([])

  const [showMailModal, setShowMailModal] = useState(false)
  const [mailContent, setMailContent] = useState({ subject: '', body: '{link}' })
  const [selectedFeedbackers, setSelectedFeedbackers] = useState<string[]>([])

  const [feedbackerToEdit, setFeedbackerToEdit] = useState<Feedbacker | null>(null)
  const [showFeedbackerModal, setShowFeedbackerModal] = useState(false)

  const [clientToEdit, setClientToEdit] = useState<Client | null>(null)
  const [showClientModal, setShowClientModal] = useState(false)


  const { updateProject } = useProject()

  const toggleModal = () => {
    setShowMailModal(!showMailModal)
  }

  useEffect(
    () => {
      if (project.clients) {
        setClients(Object.values(project.clients))
      }
      if (project.feedbackers) {
        setFeedbackers(Object.values(project.feedbackers))
      }
    }
    , [project])


  const clientColumns: GridColDef[] = [
    { field: 'firstname', headerName: 'DE Bezeichnung', flex: 0.35 },
    { field: 'lastname', headerName: 'DE Bezeichnung', flex: 0.35 },
    {
      field: 'answers', headerName: 'Rückmeldungen', flex: 0.35, renderCell: (params) => {
        let textToDisplay = '0'
        if (project.feedbackers) {
          const clientId = params.row.id
          const feedbackersOfClientWithAnswers = Object.values(project.feedbackers).filter((feedbacker: Feedbacker) =>
            Object.values(feedbacker.clients).some((client: ClientRef) => client.id === clientId && client.answers && Object.keys(client.answers)))
          const feedbackersOfClient = Object.values(project.feedbackers).filter((feedbacker: Feedbacker) =>
            Object.values(feedbacker.clients).some((client: ClientRef) => client.id === clientId))
          textToDisplay = feedbackersOfClientWithAnswers.length.toString() + '/' + feedbackersOfClient.length.toString()
        }
        return textToDisplay
      }
    },
    {
      field: 'actions', headerName: 'Aktionen', minWidth: 200, renderCell: (params) => {
        return (<>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => {
                setClientToEdit(params.row)
                setShowClientModal(true)
              }}
              variant="outlined"
            >
              Bearbeiten
            </Button>
            <Button
              color="error"
              onClick={() => deleteClient(params.row.id)}
              variant="contained"
            >
              Löschen
            </Button>
          </>
        );
      }
    }
  ]

  const feedbackerColumns: GridColDef[] = [
    {
      field: 'name', headerName: 'Name', flex: 0.25, renderCell: (params) => {
        return (<Link to={`/feedback/${project.id}/${params.row.id}`}
                      component={RouterLink}>{params.row.firstname} {params.row.lastname}</Link>)
      },
      valueGetter: params => `${params.row.firstname} ${params.row.lastname}`
    },
    {
      field: 'mail', headerName: 'Mailadresse', flex: 0.3, renderCell: (params) => {
        return (<>{params.row.mail}</>)
      }
    },
    {
      field: 'answers', headerName: 'Bewertungen', flex: 0.45, renderCell: (params) => {
        return (<>{params.row.finished ? <CheckIcon
          color="success"/> : Object.keys(params.row.clients).length > 0 && getClientsWithAnswersText(params.row.clients, project.questions ? Object.keys(project.questions).length : 0)}</>)
      }
    }
    ,
    {
      field: 'actions', headerName: 'Aktionen', minWidth: 200, renderCell: (params) => {
        return (<>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => {
                setFeedbackerToEdit(params.row)
                setShowFeedbackerModal(true)
              }}
              variant="outlined"
            >
              Bearbeiten
            </Button>
            <Button
              color="error"
              onClick={() => deleteFeedbacker(params.row.id)}
              variant="contained"
            >
              Löschen
            </Button>
          </>
        );
      }
    }
  ]

  const getClientsWithAnswersText = (clients: { [key: string]: ClientRef }, totalQuestions: number) => {
    let textToReturn = ''

    Object.values(clients).forEach(client => {
      if (client.answers) {
        textToReturn = textToReturn + `(${Object.keys(client.answers).length} /${totalQuestions}) `;
      } else {
        textToReturn = textToReturn + `(0/${totalQuestions}) `
      }
    })
    return textToReturn.length > 0 ? textToReturn : `(0/${totalQuestions})`;
  }

  const deleteFeedbacker = (feedbackerId: string) => {
    if (project.feedbackers && feedbackerId) {
      const feedbackerToDelete = project.feedbackers[feedbackerId]
      if (window.confirm(`Wollen Sie ${feedbackerToDelete.firstname} ${feedbackerToDelete.lastname} wirklich löschen ?`)) {
        const tempFeedbackers = project.feedbackers
        delete tempFeedbackers[feedbackerId]
        updateProject({ ...project, feedbackers: tempFeedbackers })
      }
    }
  }

  const deleteClient = (clientId: string) => {
    if (project.clients && clientId) {
      const hasFeedbacker = project.feedbackers && Object.values(project.feedbackers).some(feedbacker => !!feedbacker.clients?.[clientId])

      if (hasFeedbacker) {
        alert('Kann nicht gelöscht werden, weil dieser Kunde noch bei Feedbackern hinterlegt ist.');
        return
      }
      const clientToDelete = project.clients[clientId]

      if (window.confirm(`Wollen Sie ${clientToDelete.firstname} ${clientToDelete.lastname} wirklich löschen ?`)) {
        const tempClients = project.clients
        delete tempClients[clientId]
        updateProject({ ...project, client: tempClients })
      }
    }
  }

  const getSelectedRows = (e: any) => {
    setSelectedFeedbackers(e)
  }
  const sendMail = () => {

    selectedFeedbackers.forEach(feedbackerId => {
      const feedbacker = getFeedbackerById(feedbackerId)
      if (feedbacker) {
        const mailText = mailContent.body.replaceAll('{link}', `${BASEPATH}feedback/${project.id}/${feedbacker.id}`)
        sendMailCall({ to: feedbacker.mail, subject: mailContent.subject, body: mailText })
      }
    })

    console.log('send Mail to', selectedFeedbackers)
    toggleModal()
  }

  const getFeedbackerById = (feedbackerId: string): Feedbacker | undefined => {
    return feedbackers.find(feedbacker => feedbacker.id === feedbackerId)
  }

  const deletePersons = () => {
    updateProject({ ...project, clients: null, feedbackers: null })
  }


  return <>
    {feedbackerToEdit &&
      <PersonDetails project={project}
                     inputPerson={feedbackerToEdit} inputType={'feedbacker'}
                     showDialog={showFeedbackerModal} setShowDialog={setShowFeedbackerModal}
      />}

    {clientToEdit &&
      <PersonDetails project={project}
                     inputPerson={clientToEdit} inputType={'client'}
                     showDialog={showClientModal} setShowDialog={setShowClientModal}
      />}

    <Dialog
      open={showMailModal}
      onClose={toggleModal}
    >
      <DialogTitle>
        E-Mail Einladung an Feedbacker senden
      </DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: '500px', mt: 2 }}>
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Betreff"
            defaultValue={mailContent.subject}
            onChange={(event) => setMailContent({ subject: event.target.value, body: mailContent.body })}
          />
          <TextField
            sx={{ width: '100%', mb: 2 }}
            id="id"
            label="Inhalt"
            multiline
            rows={6}
            defaultValue={mailContent.body}
            onChange={(event) => setMailContent({ subject: mailContent.subject, body: event.target.value })}
          />
        </Box>
        <Box>
          {selectedFeedbackers.map((feedbackerId, index) => <span
            key={'selectedFeedbacker' + feedbackerId}>{index > 0 && ', '}{getFeedbackerById(feedbackerId)?.mail} </span>)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={toggleModal}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={sendMail}>Mails senden</Button>
      </DialogActions>
    </Dialog>

    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Kunden
      </Typography>
      <Button variant="contained" color="error" onClick={deletePersons}>
        Alle Personen löschen
      </Button>
    </Box>
    {clients &&
      <DataGrid
        columns={clientColumns}
        autoHeight
        rows={clients}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'firstname', sort: 'desc' }]
          }
        }}
      />
    }

    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, mt: 4 }}>
      <Typography variant="h6">
        Feedbacker
      </Typography>
      <Button onClick={toggleModal} variant="contained">Mails versenden</Button>
    </Box>

    {feedbackers &&
      <DataGrid
        columns={feedbackerColumns}
        autoHeight
        rows={feedbackers}
        checkboxSelection
        selectionModel={selectedFeedbackers}
        onSelectionModelChange={getSelectedRows}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
      />
    }
  </>
}

export default PersonenList
