import { Project } from '../lib/model';
import { Button } from '@mui/material';
import { ChangeEvent, Fragment, useRef } from 'react';
import { parsePersons } from '../lib/parser';
import { useProject } from '../hooks/useProject';

type Props = {
  project: Project
}
const PersonImport = ({project}: Props) =>{

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const {updateProject} = useProject()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if(file) {
     parsePersons(file).then( result =>
       {
         project.clients = result.clients
         project.feedbackers = result.feedbackers
         updateProject(project)
       }
     )
    }
  }
  return <>
    <Fragment>
      <input
        ref={uploadInputRef}
        type="file"
        accept="text/csv"
        style={{ display: "none" }}
        onChange={onChange}
      />
      <Button
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
        variant="contained"
      >
        Personen Upload
      </Button>
    </Fragment>
  </>
}

export default PersonImport
