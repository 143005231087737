import Header from '../components/header';
import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { updatePasswortCall } from '../lib/firebase';
import { AuthContext } from '../lib/context';

const ChangePassword = () => {
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { user } = useContext(AuthContext)

  const changePassword = () => {
    if (user && user['uid']) {
      updatePasswortCall({ uid: user['uid'], password }).then(() =>
        window.location.reload()
      )
        .catch(e => setError(e.message))
    }
    else{
      setError('Fehler beim laden des Benutzers.')
    }

  }
  return (
    <>
      <Header/>
      <Box sx={{m:4}}>
        <Typography component="h1" variant="h4">Passwort ändern</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button onClick={changePassword}>Passwort ändern</Button>
        {error}
      </Box>
    </>
  )
}

export default ChangePassword
