// Arithmetic mean
export const getMean = (data: number[]) => {
  data = data.filter(num => num > 0);
  if(data.length < 1){
    return  0
  }

  const mean = data.reduce((a, b) => (
    Number(a) + Number(b)
  ), 0) / data.length

  return Math.round(mean * 100) / 100
};

// Standard deviation
export const getSD = (data: number[]): number => {
  if (data.length <3) {
    return 0
  }

  data = data.filter(num => num > 0);
  const m = getMean(data);
  const sd = Math.sqrt(data.reduce((sq, n) => (
    sq + ((n - m) ** 2)
  ), 0) / (data.length - 1));
  return Math.round(sd * 100) / 100
};


export const grey = '#757575'
export const green = '#73C92D'
export const yellow = '#FCC512'
export const blue = '#537D9F'
export const darkGrey = '#333'
