import { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useContexts } from '../hooks/useContexts';
import { Context } from '../lib/model';

const Contexts = () => {
  const {contexts, updateContext, deleteContext} = useContexts()

  const [showFormModal, setShowFormModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [context, setContext] = useState<Partial<Context>>({})

  const toggleModal = () => {
    setShowFormModal(!showFormModal)
  }
  const setData = (field:string, content:string) => {
    const temp = {...context, [field]: content}
    setContext(temp)
  }

  const saveRow = () => {
    updateContext(context)
    setShowFormModal(false)
  }

  const deleteRow = (row: Context) => {
    if(window.confirm(`Wollen Sie ${row.id} wirklich löschen ?`)){
      deleteContext(row)
    }
  }

  const openCreateNew= () => {
    setContext({});
    setIsUpdate(false)
    setShowFormModal(true)
  }

  const openEditForm = (row:Context) => {
    setContext(row)
    setIsUpdate(true)
    setShowFormModal(true)
  }

  const columns: GridColDef[] = [{ field: 'id', headerName: 'Referenz', flex:0.3 },
    { field: 'de', headerName: 'DE Bezeichnung', flex: 0.35},
    { field: 'en', headerName: 'DE Bezeichnung', flex: 0.35},
    { field: 'actions', headerName: 'Aktionen', width: 220, renderCell: (params) => {
        return (<>
            <Button
              sx={{mr:2}}
              color="primary"
              onClick={() => openEditForm(params.row)}
              variant="outlined"
            >
              Bearbeiten
            </Button>
            <Button
              color="error"
              onClick={() => deleteRow(params.row)}
              variant="contained"
            >
              Löschen
            </Button>
          </>
        );
      }
    }
  ]

  return <Box sx={{ mt: 2 }}>
    <Typography variant="h2">
      Themen
    </Typography>
    <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'end'}}>
      <Button variant="contained" color="primary" onClick={openCreateNew}>
        Neues Thema erstellen
      </Button>
    </Box>
    <DataGrid
      columns={columns}
      autoHeight
      rows={contexts}
      checkboxSelection
      disableSelectionOnClick
    />
    <Dialog
      open={showFormModal}
      onClose={toggleModal}
    >
      <DialogTitle>
        {isUpdate ? 'Thema bearbeiten': 'Neues Thema'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{width:'50vw', mt:2}}>
          <TextField
            sx={{width:'80%', mb: 2}}
            id="id"
            label="Referenz"
            defaultValue={context.id}
            disabled={isUpdate}
            onChange={(event) => setData( 'id', event.target.value)}
          />
          <TextField
            sx={{width:'80%', mb: 2}}
            id="id"
            label="Deutsch"
            defaultValue={context.de}
            onChange={(event) => setData( 'de', event.target.value)}
          />
          <TextField
            sx={{width:'80%', mb: 2}}
            id="id"
            label="Englisch"
            defaultValue={context.en}
            onChange={(event) => setData( 'en', event.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={toggleModal}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={saveRow}>Speichern</Button>
      </DialogActions>
    </Dialog>
  </Box>
}

export default Contexts;
