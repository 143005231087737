import { jsPDF } from 'jspdf';
import { Client, Language } from '../model';
import { translateText } from '../pdfCreator';

const generateFrontpage = (pdf:jsPDF, client: Client, language: Language) => {
  // @ts-ignore
  pdf.addImage('/Deckblatt_360 Feedback.jpg',0,0, pdf.internal.pageSize.width, pdf.internal.pageSize.height )
  pdf.setFont('Helvetica')
  pdf.setFontSize(24)
  pdf.text(`${translateText(language, {de: 'Ergebnisbericht von', en: 'Report of'})}\n${client.firstname} ${client.lastname}`, 176,515, {angle: 15.0})
}

export default generateFrontpage
