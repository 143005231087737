import { onAuthStateChanged } from 'firebase/auth';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { firebaseAuth, firebaseDb } from './firebase';
import { LangElement } from './model';
import { doc, getDoc } from 'firebase/firestore';

export const AuthContext = React.createContext({ user: null, isAdmin: false });

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any | null>(null);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (newUser) => {
      if (newUser) {
        const usermail: string = newUser['email'] ?? ''
        if (usermail) {
          getDoc(doc(firebaseDb, 'admins', usermail)).then(docSnap => {
            if (docSnap.exists()) {
              setIsAdmin(true)
            }
          }).catch(() => setIsAdmin(false))
        }
      } else {
        setIsAdmin(false)
      }
      setUser(newUser)
    });
  }, []);


  return (
    // @ts-ignore
    <AuthContext.Provider value={{ user, isAdmin }}>{children}</AuthContext.Provider>
  )
};


export const LanguageContext = React.createContext<{ language?: string, setLanguage?: Dispatch<SetStateAction<string>>, translateText?: (multilingualText: LangElement) => string }>({});
