import React, { useContext, useState } from 'react'
import Header from '../components/header';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Project } from '../lib/model';
import * as _ from 'lodash';
import { useProjects } from '../hooks/useProjects';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../hooks/useProject';
import { AuthContext } from '../lib/context';


const Projects = () => {
  const [showFormModal, setShowFormModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [project, setProject] = useState<Partial<Project>>({})
  const [useGerman, setUseGerman] = useState(false)
  const [useEnglish, setUseEnglish] = useState(false)

  const { projects } = useProjects()
  const { deleteProject, updateProject } = useProject()
  const navigate = useNavigate()

  const toggleModal = () => {
    setShowFormModal(!showFormModal)
  }

  const { isAdmin } = useContext(AuthContext)

  const toggleUseGerman = () => {
    if (useGerman) {
      setData('clientBanner.de', '')
    }
    setUseGerman(!useGerman)
  }

  const toggleUseEnglish = () => {
    if (useEnglish) {
      setData('clientBanner.en', '')
    }
    setUseEnglish(!useEnglish)
  }

  const setData = (field: string, content: string) => {
    const temp = _.cloneDeep(project)
    _.set(temp, field, content)
    setProject(temp)
  }


  const openCreateNew = () => {
    setProject({ id: uuidv4(), active: false, created: new Date() });
    setIsUpdate(false)
    setUseEnglish(false)
    setUseGerman(false)
    setShowFormModal(true)
  }

  const saveRow = () => {
    updateProject(project)
    setShowFormModal(false)
  }

  const openEdit = (projectToEdit: Project) => {
    setProject(projectToEdit);

    setUseGerman(!!projectToEdit.clientBanner?.de ?? false)
    setUseEnglish(!!projectToEdit.clientBanner?.en ?? false)

    setIsUpdate(true)
    setShowFormModal(true)
  }

  const deleteRow = (project: Project) => {
    if (window.confirm(`Wollen Sie ${project.name} wirklich löschen ?`)) {
      deleteProject(project)
    }
  }

  const setActiveFlag = (project: Project) => {
    project.active = !project.active
    setProject(project)
    updateProject(project)
  }

  const columns: GridColDef[] = [{ field: 'id', headerName: 'Id', width: 90 },
    {
      field: 'activ', headerName: 'Aktiv', width: 80,
      renderCell: (params) => {
        return (<Switch checked={params.row.active} onClick={() => setActiveFlag(params.row)}/>)
      }
    },
    {
      field: 'name', headerName: 'Name', flex: 0.3,
      renderCell: (params) => {
        return (<>{params.row.name ?? '-'}</>)
      }
    },
    {
      field: 'created', headerName: 'Erstellt am', flex: 0.3,
      renderCell: (params) => {
        return (<>{params.row.created.toDate().toLocaleDateString() ?? '-'}</>)
      }
    },
    {
      field: 'actions', headerName: 'Aktionen', minWidth: 500, renderCell: (params) => {
        return (<>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => navigate(`/analyse/${params.row.id}`)}
              variant="outlined"
            >
              Analyse
            </Button>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => navigate(`/projekte/${params.row.id}`)}
              variant="outlined"
            >
              Fragen bearbeiten
            </Button>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => openEdit(params.row)}
              variant="outlined"
            >
              Stammdaten bearbeiten
            </Button>
            <Button
              color="error"
              onClick={() => deleteRow(params.row)}
              variant="contained"
            >
              Löschen
            </Button>
          </>
        );
      }
    }]

  return <>
    <Header/>
    {isAdmin ?
      <>
        <Box sx={{ m: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Projekte
          </Typography>
          <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'start' }}>
            <Button variant="contained" color="primary" onClick={openCreateNew}>
              Neues Projekt
            </Button>
          </Box>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'created', sort: 'desc' }]
              }
            }}
            columns={columns}
            autoHeight
            rows={projects}
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>

        <Dialog
          open={showFormModal}
          onClose={toggleModal}
        >
          <DialogTitle>
            {isUpdate ? 'Projekt bearbeiten' : 'Neues Projekt'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2, minWidth: '500px' }}>
              <TextField
                sx={{ width: '100%', mb: 2 }}
                id="id"
                label="Name"
                defaultValue={project.name}
                disabled={isUpdate}
                onChange={(event) => setData('name', event.target.value)}
              />
              <Box sx={{ width: '100%', mt: 2, mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h1">
                  Deutsch
                </Typography>
                <Switch checked={useGerman} onClick={toggleUseGerman}/>
              </Box>
              {useGerman && <TextField
                sx={{ width: '100%', mb: 2 }}
                id="id"
                label="Bannertext"
                multiline
                rows="4"
                defaultValue={project.clientBanner?.de}
                onChange={(event) => setData('clientBanner.de', event.target.value)}
              />}

              <Box sx={{ width: '100%', mt: 2, mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h1">
                  Englisch
                </Typography>
                <Switch checked={useEnglish} onClick={toggleUseEnglish}/>
              </Box>
              {useEnglish && <TextField
                sx={{ width: '100%', mb: 2 }}
                id="id"
                label="Bannertext"
                multiline
                rows={4}
                defaultValue={project.clientBanner?.en}
                onChange={(event) => setData('clientBanner.en', event.target.value)}
              />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={toggleModal}>Abbrechen</Button>
            <Button variant="contained" color="primary" onClick={saveRow}>Speichern</Button>
          </DialogActions>
        </Dialog>
      </>
    :
    <Box  sx={{ m: 2 }}>Sie sind nicht berechtigt für diese Seite.</Box>
    }
  </>
}

export default Projects
