import { useContext, useEffect, useRef } from 'react';
import Chart, { ChartData } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRoles } from '../hooks/useRoles';
import { useContexts } from '../hooks/useContexts';
import { LanguageContext } from '../lib/context';
import { Context, Role } from '../lib/model';


export type BarChartData = {
  role: string,
  mean: number,
  sd: number,
}[]

type Props = {
  data: BarChartData,
  contextId: string,
  addCanva: (canva: React.RefObject<HTMLCanvasElement>, id: string) => void,
}
const BarChart = ({ data, contextId, addCanva }: Props) => {

  const canvas = useRef<HTMLCanvasElement>(null);
  const { roles } = useRoles()
  const { contexts } = useContexts()
  const { translateText,language } = useContext(LanguageContext)


  useEffect(() => {
    addCanva(canvas, `barChart-${contextId}`)
  }, [data])

  useEffect(() => {
    let chartData: ChartData;
    if (translateText && contexts.length > 0 && roles.length > 0) {

      const colors = data.map(element => {
        const usedRole = roles.find((currentRole: Role) => currentRole.id === element.role)
        return usedRole.color
      })

      chartData = {
        labels: data.map(element => {
          const usedRole = roles.find((currentRole: Role) => currentRole.id === element.role)
          return translateText({ de: usedRole?.de?.name.he ?? element.role, en: usedRole?.en?.name.he ?? element.role })
        }),
        datasets: [
          {
            label: 'sdplus',
            data: data.map((element, index) => {
              return { y: element.mean + element.sd, x: index }
            }),
            type: 'line',
            borderColor: '#999',
            backgroundColor: '#99',
            borderWidth: 1,
            showLine: false, // no line shown
            pointRadius: 5,
            pointHoverRadius: 8,
          },
          {
            label: 'sdminus',
            data: data.map((element, index) => {
              return { y: element.mean - element.sd, x: index }
            }),
            type: 'line',
            borderColor: '#333',
            backgroundColor: '#333',
            borderWidth: 1,
            showLine: false, // no line shown
            pointRadius: 5,
            pointHoverRadius: 8,
          },
          {
            data: data.map(element => element.mean),
            order: 0,
            type: 'bar',
            backgroundColor: colors
          },

        ]
      };

      const options = {
        devicePixelRatio: 2.5,
        animation: false,
        scales: {
          y: {
            suggestedMax: 6,
            ticks: {
              font: {
                size: 15,
                family: 'Helvetica Neue'
              },
            },
          },
          x: {
            ticks: {
              font: {
                size: 15,
                family: 'Helvetica Neue'
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            anchor: 'end',
            align: 'bottom',
            color: '#ffffff',
            display: (context: any) => context.dataset!.type === 'bar',
            font: {
              weight: 'bold',
              size: 16
            }
          }
        },
      };

      if (canvas.current) {
        const chart = new Chart(canvas.current, {
          data: chartData,
          // @ts-ignore
          options: options,
          plugins: [ChartDataLabels]
        });

        return () => {
          chart.destroy();
        };
      }
    }
  }, [data, translateText, contexts, language]);

  const getContext = () => {
    const usedContext = contexts.find((currentContext: Context) => currentContext.id === contextId)
    return translateText && usedContext && translateText({de: usedContext.de, en: usedContext.en})
  }

  return <>
    {translateText && contexts.length > 0 &&
      <div style={{ width: '500px' }}>
      <canvas ref={canvas} data-text={getContext()} data-title={translateText({de: 'Übersicht pro Kompetenzbereich', en: 'Overview the areas of competence'})}/>
    </div>}
  </>
}

export default BarChart
