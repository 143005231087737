import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { AuthProvider, LanguageContext } from './lib/context';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Feedback from './pages/feedback';
import Admin from './pages/admin';
import Projects from './pages/projects';
import Settings from './pages/settings';
import { Protect } from './lib/protect';
import Project from './pages/project';
import Analysis from './pages/analysis';
import { LangElement } from './lib/model';
import ChangePassword from './pages/changePassword';

const theme = createTheme({
  palette: {
    text: {
      primary: '#4a4e57'
    },
    primary: {
      main: '#73c92d',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#757575',
      contrastText: '#ffffff',
    },
    error:{
      main: '#880000',
      contrastText: '#ffffff'
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    'fontFamily': `"Helvetica", "Arial", sans-serif`,
    'fontSize': 14,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500,
  }
})

function App() {
  const [language, setLanguage] = useState<string>('')

  useEffect(() => {
    setLanguage(sessionStorage.getItem('lang') ?? navigator.language ?? 'de')
  }, [])

  /*useEffect(() =>
    {
      console.log('change', language, sessionStorage.getItem('lang'))
      if(!language){
        console.log('lang not existing')
        //setLanguage(sessionStorage.getItem('lang') ?? navigator.language ?? 'de')
      }
      sessionStorage.setItem('lang', 'de')
      if(language !== sessionStorage.getItem('lang')){
        console.log('set session')
        sessionStorage.setItem('lang', 'de')
      }
      console.log('after change', sessionStorage.getItem('lang'))
    }
    , [language])*/

  const translateText = (multilingualText: LangElement): string => {
    if(language === 'de' && multilingualText.de){
      return multilingualText.de
    }
    if ((!multilingualText.de || language === 'en') && multilingualText.en){
      return multilingualText.en
    }
    if(multilingualText.de){
      return multilingualText.de
    }
    return ''
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LanguageContext.Provider value={{language, setLanguage, translateText}}>
          <Routes>
            <Route path="/" element={<Feedback/>}/>
            <Route path="/feedback" element={<Feedback/>}/>
              <Route index element={<Feedback />} />
              <Route path="/feedback/:projectId/:feedbackerId" element={<Feedback />} />
            <Route path="/admin" element={<Admin/>}/>
            <Route path="/changepassword" element={<Protect fallback="/admin"><ChangePassword/></Protect>}/>
            <Route path="/projekte" element={<Protect fallback="/admin"><Projects/></Protect>}/>
            <Route path="/einstellungen" element={<Protect fallback="/admin"><Settings/></Protect>}/>
            <Route path="/projekte/:projectId" element={<Protect fallback="/admin"><Project /></Protect>} />
            <Route path="/analyse/:projectId" element={<Protect fallback="/admin"><Analysis /></Protect>} />

          </Routes>
        </LanguageContext.Provider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
