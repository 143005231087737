import { useContext, useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { firebaseAuth } from '../lib/firebase';
import { AuthContext } from '../lib/context';
import { useNavigate } from 'react-router-dom';



export const AdminSignIn = () => {
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)

  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  const doLogin = (event: any) => {
    event.preventDefault()
    setHasError(false)
    signInWithEmailAndPassword(firebaseAuth, mail, password)
      .then(result => {
        setPassword('')
        setMail('')
        // @ts-ignore
        sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
      }).catch(e => {
        setHasError(true)
        console.log(e)
    })
  }


  useEffect(() => {
    if(user){
      navigate('/projekte')
    }
  }, [user])

  return (<>
    {!user &&
      <Box sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
      }}>
        <Container maxWidth="sm" component={'form'}>
          <Typography component="h1" variant="h4">Admin-Login</Typography>
          <TextField
            error={hasError}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setMail(event.target.value)}
          />
          <TextField
            error={hasError}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={doLogin}
            type="submit"
          >
            Login
          </Button>
        </Container>
      </Box>
    }</>)
}


export default AdminSignIn
