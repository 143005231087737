import { useParams } from 'react-router-dom';
import { useProject } from '../hooks/useProject';
import { Box, Typography, MenuItem, Select } from '@mui/material';
import Header from '../components/header';
import { AnalysisBasicData, Client, Gender } from '../lib/model';
import { generateAnalysisBasic } from '../lib/analysis/generateAnalysisBasic';
import { useEffect, useState } from 'react';
import Charts from '../components/charts';
import { useRoles } from '../hooks/useRoles';

const Analysis = () => {
  const { projectId } = useParams()
  const {roles} = useRoles()
  const { project } = useProject(projectId)

  const [selectedClient, setSelectedClient] = useState<string>('')
  const [selectedClientGender, setSelectedClientGender] = useState<Gender>('m')
  const [analysisBasic, setAnalysisBasis] = useState<AnalysisBasicData>()

  useEffect(() => {
    if (selectedClient && roles) {
      setAnalysisBasis(generateAnalysisBasic(project,roles,selectedClient))
      if(project.clients[selectedClient].gender){
        setSelectedClientGender(project.clients[selectedClient].gender ?? 'm')
      }
    } else if(project?.clients) {
      const newSelectedClientId: string  = Object.keys(project.clients)[1]
      setSelectedClient(newSelectedClientId)
      if(project.clients[newSelectedClientId].gender){
        setSelectedClientGender(project.clients[newSelectedClientId].gender ?? 'm')
      }
    }
  }, [selectedClient, project, roles])

  const clientSelectionChanged = (e: any) => {
    setSelectedClient(e.target.value)
  }

  return <>
    <Header/>
    <Box sx={{ m: 2 }}>
      {project &&
        <><Typography variant="h4" sx={{ mt: 2 , mb: 2}}>
          Analysis von {project.name}
        </Typography>
          <Select value={selectedClient} onChange={clientSelectionChanged}>
            {(Object.values(project.clients) as Client[]).map(client =>
              <MenuItem key={'menu-' + client.id} value={client.id}>{client.firstname} {client.lastname}</MenuItem>
            )}
          </Select>
        </>
      }
      <div>
        {// clientGender dynamisch machen
          analysisBasic &&
            <Charts analyticsBasic={analysisBasic} project={project} clientGender={selectedClientGender} clientId={selectedClient}/>
        }
      </div>
    </Box></>
}


export default Analysis
