import { GB, DE }
  from
    'country-flag-icons/react/3x2'
import { LanguageContext } from '../lib/context';
import { useContext } from 'react';

const LanguageSelector = ({ style }:{style?:any}) => {
  const { setLanguage } = useContext(LanguageContext)
  return <div style={style}>
    <GB style={{ width: '1.5rem', cursor: 'pointer' }} onClick={() => setLanguage!('en')}/>
    <DE style={{ width: '1.5rem', marginLeft: '0.5rem', cursor: 'pointer' }} onClick={() => setLanguage!('de')}/>
  </div>
}

export default LanguageSelector
