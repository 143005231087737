import { useContext, useState } from 'react';
import { AuthContext, LanguageContext } from '../lib/context';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth, sendCodeCall } from '../lib/firebase';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import LanguageSelector from './languageSelector';

export const FeedbackerSignIn = () => {
  const [mail, setMail] = useState('')
  const [showCode, setShowCode] = useState(false)
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)

  const { user } = useContext(AuthContext)
  const { translateText, language } = useContext(LanguageContext)

  const toggleCode = () => {
    setShowCode(!showCode)
  }
  const sendCode = (event: any) => {
    event.preventDefault()
    sendCodeCall({mail, language}).then(obj => console.log('sendCodeReturn',obj)).catch(e => console.error(e))
    toggleCode()
  }

  const doLogin = (event: any) => {
    event.preventDefault()
    setHasError(false)
    signInWithEmailAndPassword(firebaseAuth, mail, password)
      .then(result => {
        setPassword('')
        setMail('')
        // @ts-ignore
        sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
        window.location.reload()
      }).catch(e => {
      setHasError(true)
      console.log(e)
    })
  }


  return (<>
    {!user &&
      <Box sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
      }}>
        <Container sx={{width:{xs: '100vw', sm: '75vw', md:'50vw'}}} component={'form'}>
          {!showCode ? <>
            <Box sx={{mb:2, display: 'flex', justifyContent: 'space-between'}} width="full">
              <Typography component="h1" variant="h5">
                {translateText!({de: 'Willkommen bei Realfeedback', en: 'Welcome to Realfeedback'})}
              </Typography>
              <LanguageSelector />
            </Box>
              <Box sx={{mb:2}}>
                {
                  translateText!({
                    de: 'Bitte geben Sie zur Authentifizierung Ihre Emailadresse an, um den Zugangscode zur Befragung zugeschickt zu bekommen.',
                    en: 'Please enter your email address for authentication in order to receive the access code for the survey.'
                  })
                }
              </Box>
              <TextField
                error={hasError}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                onChange={(event) => setMail(event.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={sendCode}
                type="submit"
              >
                Code senden
              </Button></>
            : <>
              <Box sx={{mb:2, display: 'flex', justifyContent: 'space-between'}} width="full">
                <Typography component="h1" variant="h5">
                  {translateText!({de: 'Willkommen bei Realfeedback', en: 'Welcome to Realfeedback'})}
                </Typography>
                <LanguageSelector />
              </Box>
              <Box sx={{mb:2}}>
                {
                  translateText!({
                    de: 'Sie werden einen Code via Mail erhalten, geben Sie diesen hier ein:',
                    en: 'You received the code in a mail, enter it here:'
                  })
                }
              </Box>
              <TextField
                error={hasError}
                margin="normal"
                required
                fullWidth
                name="Code"
                type="text"
                id="code"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={doLogin}
                type="submit"
              >
                Login
              </Button></>}
        </Container>
      </Box>
    }</>)
}


export default FeedbackerSignIn
